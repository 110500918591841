export const createRefinerUserData = ({ userUid, userProfile }) => ({
  id: userUid,
  email: userProfile.email,
  name: userProfile.fullName,
  office_locale: userProfile.officeLocale,
  office_created_at: userProfile.createdAt,
  office_signed_up_at: userProfile.officeSignedUpAt,
  office_has_payments: userProfile.officeHasPayments,
  office_first_payment_at: userProfile.officeFirstPaymentAt,
  office_has_trades: userProfile.officeHasTrades,
  office_count_trade: userProfile.officeCountTrade,
  office_user_counter: userProfile.officeUserCounter,
  office_has_verified: userProfile.officeHasVerified,
  office_has_withdrawals: userProfile.officeHasWithdrawals,
  office_withdrawals_count: userProfile.officeWithdrawalsCount,
});
