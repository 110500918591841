import {
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_ACCOUNTS,
  MUTATION_SET_SELECTED_ACCOUNT,
  MUTATION_SET_PAYMENT_METHODS,
  MUTATION_SET_SELECTED_PAYMENT_METHOD,
} from './types';

export default {
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts;
  },
  [MUTATION_SET_SELECTED_ACCOUNT](state, account) {
    state.selectedAccount = account;
  },
  [MUTATION_SET_PAYMENT_METHODS](state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  [MUTATION_SET_SELECTED_PAYMENT_METHOD](state, paymentMethod) {
    state.selectedPaymentMethod = paymentMethod;
  },
};
