import { Action } from '@amarkets/atlas/esm/lib/action';
import { EcnPromo } from '../entities/ecn-promo';
import { EcnPromoMapper } from '../mappers/ecn-promo-mapper';
import { MUTATION_SET_ECN_PROMO, MUTATION_SET_IS_ECN_PROMO_LOADING } from '../types';

export class FetchEcnPromo extends Action {
  _api;
  _errorHandler;

  constructor({ accountApi, errorHandler }) {
    super();

    this._api = accountApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchEcnPromo._withLoading(async () => {
      try {
        const ecnPromoMapper = new EcnPromoMapper((opts) => new EcnPromo(opts));
        const ecnPromo = await this._api.getEcnPromoEligibility(
          (data) => ecnPromoMapper.mapEcnPromo(data),
        );
        commit(MUTATION_SET_ECN_PROMO, ecnPromo);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchEcnPromo');
      }
    }, commit, MUTATION_SET_IS_ECN_PROMO_LOADING);
  }
}
