import { VuexFacadeBase } from '@amarkets/atlas/esm/lib/vuex-facade-base';
import {
  STORE_MODULE_IDENTITY,
  ACTION_FETCH_USER_DOCUMENTS,
  GETTER_IS_LOADING_STATUS,
  GETTER_USER_DOCUMENTS,
  GETTER_IS_DOCUMENTS_VERIFIED,
  ACTION_FETCH_GETID_CONFIG,
  GETTER_GETID_CONFIG,
} from '../modules/identity/types';

export class FacadeIdentity extends VuexFacadeBase {
  constructor(store) {
    super(store, STORE_MODULE_IDENTITY);
  }

  fetchUserDocuments = (payload) => this.dispatch(ACTION_FETCH_USER_DOCUMENTS, payload);
  userDocuments = () => this.getters(GETTER_USER_DOCUMENTS);
  isDocumentVerified = () => this.getters(GETTER_IS_DOCUMENTS_VERIFIED);
  isLoading = () => this.getters(GETTER_IS_LOADING_STATUS);

  fetchGetidConfig = (payload) => this.dispatch(ACTION_FETCH_GETID_CONFIG, payload);
  getGetidConfig = () => this.getters(GETTER_GETID_CONFIG);
}
