import {
  ACTION_GENERATE_TOTP,
  ACTION_ENABLE_2FA,
} from '../types';
import { GenerateTotp } from './generate-totp';
import { Enable2fa } from './enable-2fa';

export default function ({
  apiServices,
  errorHandler,
  httpErrorParser,
  uiNotifier,
  i18n,
}) {
  const { twoFactorApi } = apiServices;

  const generateTotp = new GenerateTotp({ twoFactorApi });
  const enable2fa = new Enable2fa({
    twoFactorApi,
    httpErrorParser,
    errorHandler,
    uiNotifier,
    i18n,
  });

  return {
    [ACTION_GENERATE_TOTP]: generateTotp.execute.bind(generateTotp),
    [ACTION_ENABLE_2FA]: enable2fa.execute.bind(enable2fa),
  };
}
