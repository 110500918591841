// module name
export const STORE_MODULE_IDENTITY = 'STORE_MODULE_IDENTITY';

// actions
export const ACTION_FETCH_USER_DOCUMENTS = 'ACTION_FETCH_USER_DOCUMENTS';
export const ACTION_FETCH_GETID_CONFIG = 'ACTION_FETCH_GETID_CONFIG';

// mutations
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';
export const MUTATION_SET_USER_DOCUMENTS = 'MUTATION_SET_USER_DOCUMENTS';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_GETID_CONFIG = 'MUTATION_SET_GETID_CONFIG';

// getters
export const GETTER_IS_LOADING_STATUS = 'GETTER_IS_LOADING_STATUS';
export const GETTER_USER_DOCUMENTS = 'GETTER_USER_DOCUMENTS';
export const GETTER_IS_DOCUMENTS_VERIFIED = 'GETTER_IS_DOCUMENTS_VERIFIED';
export const GETTER_GETID_CONFIG = 'GETTER_GETID_CONFIG';
