import { handleError } from '@/lib/helpers/handle-error';
import {
  ACTION_CREATE_PHONE_ORDER,
  ACTION_SEND_CONFIRMATION_CODE,
  ACTION_CONFIRM_PHONE_CODE,
  ACTION_GET_PHONE_CONFIRMATION_CODE_INFO,
} from '../types';
import { CreatePhoneOrder } from './create-phone-order';
import { SendConfirmationCode } from './send-confirmation-code';
import { ConfirmPhoneCode } from './confirm-phone-code';
import { GetPhoneConfirmationCodeInfo } from './get-phone-confirmation-code-info';

export default function ({
  apiServices,
  errorHandler,
  httpErrorParser,
  uiNotifier,
  i18n,
}) {
  const { identifyApi } = apiServices;
  const _handleError = handleError(httpErrorParser, errorHandler);

  const createPhoneOrder = new CreatePhoneOrder({
    identifyApi,
    handleError: _handleError,
  });

  const sendConfirmationCode = new SendConfirmationCode({
    identifyApi,
    errorHandler,
    uiNotifier,
    i18n,
  });

  const getPhoneConfirmationCodeInfo = new GetPhoneConfirmationCodeInfo({
    identifyApi,
    errorHandler,
  });

  const confirmPhoneCode = new ConfirmPhoneCode({
    identifyApi,
    handleError: _handleError,
  });

  return {
    [ACTION_CREATE_PHONE_ORDER]: createPhoneOrder.execute.bind(createPhoneOrder),
    [ACTION_SEND_CONFIRMATION_CODE]: sendConfirmationCode.execute.bind(sendConfirmationCode),
    [ACTION_CONFIRM_PHONE_CODE]: confirmPhoneCode.execute.bind(confirmPhoneCode),
    [ACTION_GET_PHONE_CONFIRMATION_CODE_INFO]: getPhoneConfirmationCodeInfo.execute.bind(getPhoneConfirmationCodeInfo),
  };
}
