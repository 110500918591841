import { AppModuleNext } from '@amarkets/atlas/esm/lib/app-module-next';
import { PAYMENT_METHOD_TYPES } from '@/constants';
import { STORE_MODULE_NEW_PAYMENT_METHOD } from './const';

const rootComponent = () => import(
  './components/module-root/module-root.vue'
);

const storeAsyncFactory = () => import(
  './store'
);

const props = {
  paymentType: {
    type: String,
    default: PAYMENT_METHOD_TYPES.CARD,
  },
  payment: {
    type: Object,
    default: () => ({}),
  },
  isVerificationRequired: {
    type: Boolean,
    required: true,
  },
};

const propsProviderFactory = (moduleRoot) => ({
  getPaymentType: () => moduleRoot.paymentType,
  getPayment: () => moduleRoot.payment,
  getIsVerificationRequired: () => moduleRoot.isVerificationRequired,
});

const eventsFactory = (moduleRoot) => ({
  eventChangeStep($event) {
    moduleRoot.$emit('change-step', $event);
  },
});

export default {
  module: new AppModuleNext(rootComponent, {
    storeModuleName: STORE_MODULE_NEW_PAYMENT_METHOD,
    storeAsyncFactory,
    propsProviderFactory,
    props,
    eventsFactory,
  }),
};
