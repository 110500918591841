// actions
export const ACTION_DISABLE_2FA = 'ACTION_DISABLE_2FA';

// mutations
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_MESSAGE_ERROR = 'MUTATION_SET_MESSAGE_ERROR';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';

// getters
export const GETTER_IS_LOADING = 'GETTER_IS_LOADING';
export const GETTER_VALIDATION_ERROR_MESSAGE = 'GETTER_VALIDATION_ERROR_MESSAGE';
