// actions
export const ACTION_SEND_RECOVERY_EMAIL = 'ACTION_SEND_RECOVERY_EMAIL';
export const ACTION_SET_PASSWORD = 'ACTION_SET_PASSWORD';
export const ACTION_CHANGE_PASSWORD = 'ACTION_CHANGE_PASSWORD';

// mutations
export const MUTATION_SET_IS_LOADING_PASSWORD = 'MUTATION_SET_IS_LOADING_PASSWORD';
export const MUTATION_SET_MESSAGE_ERROR = 'MUTATION_SET_MESSAGE_ERROR';

// getters
export const GETTER_IS_PASSWORD_LOADING_STATUS = 'GETTER_IS_PASSWORD_LOADING_STATUS';
export const GETTER_MESSAGE_ERROR = 'GETTER_MESSAGE_ERROR';
