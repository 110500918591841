<template>
  <div>
    something went wrong please try again later...
  </div>
</template>

<script>
export default {
  name: 'layout-init-error',
};
</script>
