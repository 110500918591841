import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class SessionsMapper extends Mapper {
  _sessionsFactory;

  constructor(sessionsFactory) {
    super();

    this._sessionsFactory = sessionsFactory;
  }

  mapSessions = (response) => SessionsMapper._getResult(response.data, this._statusFactory);
}
