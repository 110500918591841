import {
  ACTION_LOGOUT,
  ACTION_FETCH_2FA_STATUS,
  ACTION_GET_LIST_OF_SESSIONS,
  ACTION_DELETE_ALL_SESSIONS_EXCEPT_CURRENT,
  UPDATE_USER_LOCALE,
} from '../types';
import { Logout } from './logout';
import { Get2faStatus } from './get-2fa-status';
import { UpdateLocale } from './update-locale';
import { GetListOfUserSessions } from './get-sessions';
import { DeleteSessions } from './delete-sessions';

export default function ({
  apiServices: { authApi, officeApi, twoFactorApi },
  ssoClient,
  jwtService,
  errorHandler,
  globalLoader,
  uiNotifier,
  i18n,
}) {
  const logout = new Logout({
    authApi,
    ssoClient,
    jwtService,
    errorHandler,
    globalLoader,
  });

  const get2faStatus = new Get2faStatus({ twoFactorApi, errorHandler });

  const updateLocale = new UpdateLocale({ officeApi, globalLoader, errorHandler });

  const getListOfUserSessions = new GetListOfUserSessions({ authApi, errorHandler });

  const deleteSessions = new DeleteSessions({ authApi, errorHandler, uiNotifier, i18n });

  return {
    [ACTION_LOGOUT]: logout.execute.bind(logout),
    [ACTION_FETCH_2FA_STATUS]: get2faStatus.execute.bind(get2faStatus),
    [UPDATE_USER_LOCALE]: updateLocale.execute.bind(updateLocale),
    [ACTION_GET_LIST_OF_SESSIONS]: getListOfUserSessions.execute.bind(getListOfUserSessions),
    [ACTION_DELETE_ALL_SESSIONS_EXCEPT_CURRENT]: deleteSessions.execute.bind(deleteSessions),
  };
}
