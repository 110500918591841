export default {
  BY_STATUS_CODE: {
    401: 'common.error_status.401',
    403: 'common.error_status.403',
    404: 'common.error_status.404',
    410: 'common.error_status.410',
    429: 'common.error_status.429',
    500: 'common.error_status.500',
    502: 'common.error_status.502',
  },
  DEFAULT: 'common.error.default',
};
