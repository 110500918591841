import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class PhoneConfirmationCodeInfoMapper extends Mapper {
  _phoneConfirmationCodeInfoDataFactory;

  constructor(getPhoneConfirmationCodeInfoDataFactory) {
    super();

    this._phoneConfirmationCodeInfoDataFactory = getPhoneConfirmationCodeInfoDataFactory;
  }

  mapGetPhoneConfirmationCodeInfo = (response) => PhoneConfirmationCodeInfoMapper._getResult({
    codeSentAt: response.data.attributes.sentAt || 0,
    otpDeliveryMethod: response.data.attributes.otpDeliveryMethod,
  }, this._phoneConfirmationCodeInfoDataFactory);
}
