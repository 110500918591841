import { DOCUMENT_STATUSES } from '@/constants';
import {
  GETTER_USER_DOCUMENTS,
  GETTER_IS_LOADING_STATUS,
  GETTER_IS_DOCUMENTS_VERIFIED,
  GETTER_GETID_CONFIG,
} from './types';

export default {
  [GETTER_USER_DOCUMENTS]: ({ documents }) => documents,
  [GETTER_IS_DOCUMENTS_VERIFIED]: ({ documents }) => documents?.status === DOCUMENT_STATUSES.ACCEPTED,
  [GETTER_IS_LOADING_STATUS]: ({ isLoading }) => isLoading,
  [GETTER_GETID_CONFIG]: ({ getidConfig }) => getidConfig,
};
