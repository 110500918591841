import { PAYMENT_METHOD_NAMES } from './payment-method-names';
import { PAYMENT_METHOD_TYPES } from './payment-method-types';

export const PAYMENT_METHOD_TYPES_TO_NAMES = {
  [PAYMENT_METHOD_TYPES.CARD]: PAYMENT_METHOD_NAMES.payment_method_card,
  [PAYMENT_METHOD_TYPES.EPS]: PAYMENT_METHOD_NAMES.payment_method_eps,
  [PAYMENT_METHOD_TYPES.BANK]: PAYMENT_METHOD_NAMES.payment_method_bank,
  [PAYMENT_METHOD_TYPES.CRYPTO]: PAYMENT_METHOD_NAMES.payment_method_crypto,
  [PAYMENT_METHOD_TYPES.ACARD]: PAYMENT_METHOD_NAMES.payment_method_acard,
  [PAYMENT_METHOD_TYPES.ACARD_GLOBAL]: PAYMENT_METHOD_NAMES.payment_method_acard,
};
