import { Action } from '@amarkets/atlas/esm/lib/action';
import { DigestItem } from '../entities/digest-item';
import { SingleItemMapper } from '../mappers/single-item-mapper';
import { MUTATION_SET_DIGEST_ITEM } from '../types';

export class FetchDigestItem extends Action {
  _api;
  _errorHandler;

  constructor({ premiumAnalyticsApi, errorHandler }) {
    super();
    this._api = premiumAnalyticsApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { digestId, locale, isPreview = true }) {
    try {
      const itemMapper = new SingleItemMapper((opts) => new DigestItem({ ...opts, locale, isPreview }));
      const apiMethod = isPreview ? this._api.getDigestItemPreview : this._api.getDigestItem;
      const digestsItem = await apiMethod.call(this._api, { digestId }, (data) => itemMapper.mapItem(data));

      commit(MUTATION_SET_DIGEST_ITEM, digestsItem);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchDigestItem');
    }
  }
}
