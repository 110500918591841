import { createRouter, createWebHistory } from 'vue-router';
import { ROUTES, LAYOUT_NAMES, PROFILE_ROUTES } from '@/constants';
import { financeRoutes } from './finance';
import { profileRoutes } from './profile';
import { settingsRoutes } from './settings';
import { servicesRoutes } from './services';

const routes = [
  {
    path: ROUTES.ROOT.PATH,
    name: ROUTES.MAIN.NAME,
    component: () => import(
      '@/views/page-home/page-home.vue'
    ),
    meta: { layout: LAYOUT_NAMES.MAIN },
  },
  {
    path: ROUTES.USER_BLOCKED.PATH,
    name: ROUTES.USER_BLOCKED.NAME,
    component: () => import(
      '@/views/page-user-blocked/page-user-blocked.vue'
    ),
    meta: { layout: LAYOUT_NAMES.MAIN },
  },
  {
    path: ROUTES.GET_ID_COUSIN.PATH,
    name: ROUTES.GET_ID_COUSIN.NAME,
    component: () => import(
      '@/views/page-get-id-cousin/page-get-id-cousin.vue'
    ),
    meta: { layout: LAYOUT_NAMES.MAIN },
  },
  ...financeRoutes,
  ...profileRoutes,
  ...settingsRoutes,
  ...servicesRoutes,
  {
    path: ROUTES.DEBUG.PATH,
    name: ROUTES.DEBUG.NAME,
    component: () => import(
      /* webpackChunkName: "page-debug" */
      '@/views/page-debug/page-debug.vue'
    ),
    meta: { layout: LAYOUT_NAMES.EMPTY },
  },
  {
    path: ROUTES.MT_WEB.PATH,
    name: ROUTES.MT_WEB.NAME,
    component: () => import(
      /* webpackChunkName: "page-mt-web" */
      '@/views/page-mt-web/page-mt-web.vue'
    ),
    props: true,
    meta: { layout: LAYOUT_NAMES.EMPTY },
  },
  {
    path: ROUTES.SECURITY.PATH,
    redirect: { name: ROUTES.SECURITY.NAME },
  },
  {
    path: ROUTES.FRIENDS_AND_AWARDS.PATH,
    name: ROUTES.FRIENDS_AND_AWARDS.NAME,
    component: () => import(
      /* webpackChunkName: "page-friends-and-awards" */
      '@/views/page-friends-and-awards/page-friends-and-awards.vue'
    ),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: { name: ROUTES.MAIN.NAME },
  },
];

export const useRouter = ({ config }) => createRouter({
  history: createWebHistory((config.APP.ROUTER_BASE || import.meta.env.VITE_ROUTER_BASE || '/')),
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    const preserveScrollRoutes = Object.values(PROFILE_ROUTES)
      .filter((route) => route !== PROFILE_ROUTES.SESSIONS).map((route) => route.NAME);

    if (preserveScrollRoutes.includes(to.name)
      || (preserveScrollRoutes.includes(from.name) && to.name === ROUTES.PROFILE.NAME)) {
      return savedPosition;
    }
    return { top: 0 };
  },
});
