import { Action } from '@amarkets/atlas/esm/lib/action';
import { DEPOSIT_ACCOUNT_STATUSES, DEPOSIT_ACCOUNT_TYPES } from '@/constants/index';
import { AccountItem } from '../entities/account-item';
import { AccountsMapper } from '../mappers/accounts-mapper';
import { MUTATION_SET_ACCOUNTS } from '../types';

export class FetchAccounts extends Action {
  _api;
  _errorHandler;

  constructor({ depositApi, errorHandler }) {
    super();
    this._api = depositApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    try {
      const accountsMapper = new AccountsMapper((opts) => new AccountItem(opts));
      const resp = await this._api.getAllAccounts((data) => accountsMapper.mapAccounts(data));
      const sortedAndFilteredAccounts = resp
        .filter((item) => (item.status === DEPOSIT_ACCOUNT_STATUSES.ACTIVE && item.name && item.name !== 'null'))
        .sort((itemA, itemB) => {
          if (itemA.type === DEPOSIT_ACCOUNT_TYPES.WALLET) {
            return 1;
          }

          return itemB.balance.amount - itemA.balance.amount;
        });

      commit(MUTATION_SET_ACCOUNTS, sortedAndFilteredAccounts);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchAccounts');
    }
  }
}
