import {
  GETTER_DIGESTS_PREVIEW,
  GETTER_DAILY_EVENTS,
  GETTER_IDEAS,
  GETTER_DIGEST_ITEM,
  GETTER_EVENT_ITEM,
  GETTER_IDEA_ITEM,
  GETTER_TAGS,
} from './types';

export default {
  [GETTER_DIGESTS_PREVIEW]: ({ digestsPreview }) => digestsPreview,
  [GETTER_DAILY_EVENTS]: ({ dailyEvents }) => dailyEvents,
  [GETTER_IDEAS]: ({ ideas }) => ideas,
  [GETTER_DIGEST_ITEM]: ({ digestItems }) => (id) => digestItems.find((item) => item.id === id),
  [GETTER_EVENT_ITEM]: ({ eventItems }) => (id) => eventItems.find((item) => item.id === id),
  [GETTER_IDEA_ITEM]: ({ ideaItems }) => (id) => ideaItems.find((item) => item.id === id),
  [GETTER_TAGS]: ({ tags }) => tags,
};
