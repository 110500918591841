export const STORE_MODULE_NEW_PAYMENT_METHOD = 'STORE_MODULE_NEW_PAYMENT_METHOD';

export const VERIFICATION_PAYMENT_METHOD_TYPES = {
  REQUEST: 'request',
  RETURN: 'return',
};

export const CARD_TYPES = {
  NAME: 'name',
  NON_NAME: 'nonName',
  VIRTUAL: 'virtual',
};
