export class EcnPromo {
  constructor({
    isPromoPeriod,
    promoAccountExists,
    isMinPaymentPassed,
  }) {
    Object.assign(this, {
      isPromoActive: isPromoPeriod,
      isUserHasActivePromo: promoAccountExists,
      isUserPayedEnough: isMinPaymentPassed,
    });
  }
}
