import { Action } from '@amarkets/atlas/esm/lib/action';
import { Accounts } from '@/lib/entities/accounts';
import { AccountsNumberMapper } from '../mappers/accounts-number-mapper';
import {
  MUTATION_SET_ACCOUNTS_DATA,
  MUTATION_SET_ACCOUNTS_DATA_LOADING,
} from '../types';

export class FetchAccountsNumber extends Action {
  _api;

  constructor({ accountApi }) {
    super();
    this._api = accountApi;
  }

  async execute({ commit }) {
    await FetchAccountsNumber._withLoading(async () => {
      try {
        const accountsNumberMapper = new AccountsNumberMapper((opts) => new Accounts(opts));
        const accountsData = await this._api.getNumberOfAccounts(
          (data) => accountsNumberMapper.mapAccountsNumber(data),
        );

        commit(MUTATION_SET_ACCOUNTS_DATA, accountsData);
      } catch (e) {
        console.error(e, 'while execute FetchAccountsNumber');
      }
    }, commit, MUTATION_SET_ACCOUNTS_DATA_LOADING);
  }
}
