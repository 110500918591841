import { handleError } from '@/lib/helpers/handle-error';
import { OpenAccount } from './open-account';
import { OpenDemoAccount } from './open-demo-account';
import {
  ACTION_OPEN_ACCOUNT,
  ACTION_OPEN_DEMO_ACCOUNT,
  ACTION_FETCH_ACCOUNT_GROUPS,
} from '../types';
import { FetchAccountGroups } from './fetch-account-groups';

export default function ({
  apiServices,
  errorHandler,
  httpErrorParser,
  uiNotifier,
}) {
  const { officeApi } = apiServices;
  const _handleError = handleError(httpErrorParser, errorHandler);
  const openAccount = new OpenAccount({
    officeApi,
    uiNotifier,
    handleError: _handleError,
  });
  const openDemoAccount = new OpenDemoAccount({
    officeApi,
    uiNotifier,
    handleError: _handleError,
  });
  const fetchAccountGroups = new FetchAccountGroups({
    officeApi,
    handleError: _handleError,
  });
  return {
    [ACTION_OPEN_ACCOUNT]: openAccount.execute.bind(openAccount),
    [ACTION_OPEN_DEMO_ACCOUNT]: openDemoAccount.execute.bind(openDemoAccount),
    [ACTION_FETCH_ACCOUNT_GROUPS]: fetchAccountGroups.execute.bind(fetchAccountGroups),
  };
}
