/**
 * States which are represents the initialization state of the application.
 *
 * It is used only during the initialization phase of the application
 * to decide what should be shown to the user during one of the states.
 *
 * ERROR - stub error cmp
 * LOADING - stub spinner cmp
 * LOADED - real application
 */
export default {
  ERROR: 0,
  LOADING: 1,
  LOADED: 2,
};
