import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class AccountProgressMapper extends Mapper {
  _accountProgressFactory;

  constructor(accountProgressFactory) {
    super();
    this._accountProgressFactory = accountProgressFactory;
  }

  mapAccountProgress = (response) => {
    const accountProgress = response.data.attributes;

    if (accountProgress) {
      return AccountProgressMapper._getResult(
        accountProgress, this._accountProgressFactory,
      );
    }

    return false;
  };
}
