import { Action } from '@amarkets/atlas/esm/lib/action';
import { getLocationWindow } from '@/lib/utils';

export class Logout extends Action {
  _api;
  _ssoClient;
  _jwtService;
  _errorHandler;

  constructor({
    authApi,
    ssoClient,
    jwtService,
    errorHandler,
    globalLoader,
  }) {
    super();
    this._api = authApi;
    this._ssoClient = ssoClient;
    this._jwtService = jwtService;
    this._errorHandler = errorHandler;
    this._globalLoader = globalLoader;
  }

  async execute() {
    try {
      this._globalLoader.setToLoading();

      // Revoke user JWT and SSO
      // TODO: временно убрано нормальное решение и переделан на костыль, который нужно будет убрать
      // await this._api.logout(this._jwtService.refreshToken);
      const endpointOriginal = import.meta.env.VITE_ENV === 'development'
        ? 'https://abc.amarkets.org'
        : getLocationWindow().origin;

      await fetch(`${endpointOriginal}/auth/session/sign_out?refresh_token=${this._jwtService.refreshToken}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this._jwtService.accessToken}`,
        },
      });

      this._ssoClient.authorize();
    } catch (e) {
      this._errorHandler.handle(e, 'while execute logout');
      this._globalLoader.setToError();
    } finally {
      this._jwtService.removeTokens();
    }
  }
}
