import moduleSecurity from '@/modules/security';
import moduleEmail from '@/modules/email';
import modulePhone from '@/modules/phone';
import moduleIdentity from '@/modules/identity';
import moduleDisableSecondFactor from '@/modules/disable-second-factor';
import moduleAddPaymentMethod from '@/modules/add-payment-method';

export function registerFeatureModules(app, context) {
  const featureModules = {
    moduleSecurity: moduleSecurity.module,
    moduleEmail: moduleEmail.module,
    modulePhone: modulePhone.module,
    moduleIdentity: moduleIdentity.module,
    moduleDisable2fa: moduleDisableSecondFactor.module,
    moduleAddPaymentMethod: moduleAddPaymentMethod.module,
  };

  Object
    .entries(featureModules)
    .forEach(([moduleName, module]) => {
      module.register({ moduleName, app, context });
    });
}
