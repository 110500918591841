import {
  GETTER_IS_LOADING,
  GETTER_ACCOUNTS,
  GETTER_SELECTED_ACCOUNT,
  GETTER_PAYMENT_METHODS,
  GETTER_SELECTED_PAYMENT_METHOD,
  GETTER_RECOMMENDED_PAYMENT_METHODS,
} from './types';

export default {
  [GETTER_IS_LOADING]: ({ isLoading }) => isLoading,
  [GETTER_ACCOUNTS]: ({ accounts }) => accounts,
  [GETTER_SELECTED_ACCOUNT]: ({ selectedAccount }) => selectedAccount,
  [GETTER_PAYMENT_METHODS]: ({ paymentMethods }) => (country) => paymentMethods.filter((paymentMethod) => {
    const publicForArray = paymentMethod.publicFor.split(', ');
    return publicForArray.includes(country.toLowerCase());
  }),
  [GETTER_SELECTED_PAYMENT_METHOD]: ({ selectedPaymentMethod }) => selectedPaymentMethod,
  [GETTER_RECOMMENDED_PAYMENT_METHODS]: ({ paymentMethods }) => (country) => paymentMethods.filter((paymentMethod) => {
    if (!paymentMethod.recommendedForCountry) {
      return false;
    }

    const recommendedForArray = paymentMethod.recommendedForCountry.split(', ');
    return recommendedForArray.includes(country.toLowerCase());
  }),
};
