import {
  GETTER_IS_LOADING_STATUS,
  GETTER_MESSAGE_ERROR,
  GETTER_USED_PHONE,
  GETTER_PHONE_ORDER_ID,
  GETTER_ATTEMPTS,
  GETTER_CODE_SENT_AT,
  GETTER_IS_MOBILE,
  GETTER_VERIFICATION_METHOD,
} from './types';

export default {
  [GETTER_IS_LOADING_STATUS]: ({ isLoading }) => isLoading,
  [GETTER_MESSAGE_ERROR]: ({ errorMessage }) => errorMessage,
  [GETTER_USED_PHONE]: ({ usedPhone }) => usedPhone,
  [GETTER_PHONE_ORDER_ID]: ({ phoneOrderId }) => phoneOrderId,
  [GETTER_ATTEMPTS]: ({ attempts }) => attempts,
  [GETTER_CODE_SENT_AT]: ({ codeSentAt }) => codeSentAt,
  [GETTER_IS_MOBILE]: ({ isMobile }) => isMobile,
  [GETTER_VERIFICATION_METHOD]: ({ verificationMethod }) => verificationMethod,
};
