import { Action } from '@amarkets/atlas/esm/lib/action';
import { isEqual } from '@amarkets/atlas/esm/lib/is-equal';
import { HTTP_ERRORS } from '../../../const';
import {
  MUTATION_SET_MESSAGE_VALIDATION_ERROR,
  MUTATION_SET_ENABLE_2FA_LOADING,
} from '../types';

export class Enable2fa extends Action {
  _api;
  _httpErrorParser;
  _errorHandler;

  constructor({
    twoFactorApi,
    httpErrorParser,
    errorHandler,
    uiNotifier,
    i18n,
  }) {
    super();

    this._api = twoFactorApi;
    this._httpErrorParser = httpErrorParser;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute({ commit }, {
    body,
    onSuccess = () => {},
  }) {
    await Enable2fa._withLoading(async () => {
      try {
        await this._api.saveTotp(body);
        this._uiNotifier.success(this._i18n.global.t('security.dialogSuccessfulMessage.enable.title'));
        onSuccess();
      } catch (error) {
        this._handleError(error, commit);
      }
    }, commit, MUTATION_SET_ENABLE_2FA_LOADING);
  }

  _handleError(error, commit) {
    const parsedError = this._httpErrorParser.parse({ error });
    const statusCode = parsedError?.statusCode;
    const hasError = () => statusCode && isEqual(statusCode, HTTP_ERRORS.VALIDATION_ERROR);

    if (hasError()) {
      commit(MUTATION_SET_MESSAGE_VALIDATION_ERROR, parsedError?.msg);
      return;
    }

    this._errorHandler.handle(error, 'while execute SaveTotp');
  }
}
