export class AccountPassword {
  constructor({
    uuid,
    password,
    createdAt,
    updatedAt,
  }) {
    Object.assign(this, {
      uuid,
      password,
      createdAt,
      updatedAt,
    });
  }
}
