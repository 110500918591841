import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class AccountsNumberMapper extends Mapper {
  _accountsFactory;

  constructor(accountsFactory) {
    super();
    this._accountsFactory = accountsFactory;
  }

  mapAccountsNumber = (response) => AccountsNumberMapper._getResult(
    response.data.attributes, this._accountsFactory,
  );
}
