export class TagItem {
  constructor({
    id,
    name,
  }) {
    Object.assign(this, {
      id,
      text: name,
      value: name,
    });
  }
}
