export class AccountGroup {
  constructor({
    name,
    platform,
    currency,
    type,
    executionType,
    leverages,
    options,
  }) {
    Object.assign(this, {
      name,
      platform,
      currency,
      type,
      executionType,
      leverages,
      netting: options.netting,
    });
  }
}
