import { VuexHelper } from '@amarkets/atlas/esm/lib/vuex-helper';
import createActions from './email/actions';
import state from './email/state';
import mutations from './email/mutations';
import getters from './email/getters';

export default function (context) {
  return VuexHelper.createStoreModule({
    states: [state],
    actions: [createActions(context)],
    mutations: [mutations],
    getters: [getters],
  });
}
