export class Crypto {
  static _MODEL = {
    number: '',
    currency: '',
  };

  constructor({ number, currency }) {
    Object.assign(this, {
      currency: currency ?? Crypto._MODEL.currency,
      number: number ?? Crypto._MODEL.number,
    });
  }
}
