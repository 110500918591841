import { Action } from '@amarkets/atlas/esm/lib/action';
import { pushDataLayer } from '@/lib/utils';
import { MUTATION_SET_USED_EMAIL, MUTATION_SET_IS_LOADING } from '../types';

export class SetEmail extends Action {
  _api;
  _handleError;
  _uiNotifier;
  _i18n;

  constructor({
    identifyApi,
    handleError,
    uiNotifier,
    i18n,
  }) {
    super();

    this._api = identifyApi;
    this._handleError = handleError;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute({ commit }, {
    email,
    userUid,
    onSuccess = () => { },
  } = {}) {
    await SetEmail._withLoading(async () => {
      try {
        await this._api.updateEmail({ email, userUid });

        commit(MUTATION_SET_USED_EMAIL, { email });
        onSuccess();
        this._uiNotifier.success(this._i18n.global.t('notification.success.sentСonfirmationEmail'));
        pushDataLayer({ event: 'showSuccessNotificationAfterSentConfirmationEmail' });
      } catch (error) {
        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute SetEmail',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
