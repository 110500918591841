import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_LOADING_PASSWORD } from '../types';

export class SetPassword extends Action {
  _api;
  _errorHandler;
  _uiNotifier;
  _i18n;

  constructor({
    authApi,
    errorHandler,
    uiNotifier,
    i18n,
  }) {
    super();

    this._api = authApi;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute({ commit }, {
    password,
    passwordConfirmation,
    resetPasswordToken,
  } = {}) {
    await SetPassword._withLoading(async () => {
      try {
        await this._api.setPassword({
          password,
          passwordConfirmation,
          resetPasswordToken,
        });

        this._uiNotifier.success(this._i18n.global.t('notification.success.passwordChanged'));
      } catch (error) {
        this._errorHandler.handle(error, 'while execute SetPassword');
      }
    }, commit, MUTATION_SET_IS_LOADING_PASSWORD);
  }
}
