import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS_LOADING,
  MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS,
} from '../types';
import { TradingAccount } from '../entities/trading-account';
import { TradingAccountsMapper } from '../mappers/trading-accounts-mapper';

export class FetchArchivedTradingAccounts extends Action {
  _api;
  _errorHandler;

  constructor({ officeApi, errorHandler }) {
    super();
    this._api = officeApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchArchivedTradingAccounts._withLoading(async () => {
      try {
        const tradingAccountsMapper = new TradingAccountsMapper((opts) => new TradingAccount(opts));
        const archivedTradingAccounts = await this._api.getArchivedTradingAccounts(
          (data) => tradingAccountsMapper.mapTradingAccount(data),
        );

        commit(MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS, archivedTradingAccounts);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchArchivedTradingAccounts');
      }
    }, commit, MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS_LOADING);
  }
}
