export default {
  VERIFY: {
    PATH: 'verification',
    NAME: 'verification',
  },
  EMAIL: {
    PATH: 'email/:primaryState',
    NAME: 'module-email',
  },
  PHONE: {
    PATH: 'phone/:primaryState',
    NAME: 'module-phone',
  },
  IDENTITY: {
    PATH: 'new-id-document',
    NAME: 'add-new-id',
  },
  ADD_SECOND_FACTOR: {
    PATH: '2fa',
    NAME: 'add-second-factor',
  },
  DISABLED_SECOND_FACTOR: {
    PATH: 'disable-2fa',
    NAME: 'disable-second-factor',
  },
  PASSWORD: {
    PATH: 'password',
    NAME: 'module-password',
  },
  SESSIONS: {
    PATH: '/profile/sessions',
    NAME: 'sessions',
  },
};
