import { ACTION_GET_EXCHANGE_RATE_BY_CURRENCIES } from '../types';
import { FetchExchangeRateByCurrencies } from './fetch-exchange-rate-by-currencies';

export default function ({
  apiServices,
  errorHandler,
}) {
  const { exchangeApi } = apiServices;
  const fetchExchangeRateByCurrencies = new FetchExchangeRateByCurrencies({
    exchangeApi,
    errorHandler,
  });

  return {
    [ACTION_GET_EXCHANGE_RATE_BY_CURRENCIES]: fetchExchangeRateByCurrencies.execute.bind(fetchExchangeRateByCurrencies),
  };
}
