// module name
export const STORE_MODULE_PREMIUM_ANALYTICS = 'STORE_MODULE_PREMIUM_ANALYTICS';

// actions
export const ACTION_FETCH_DIGESTS_PREVIEW = 'ACTION_FETCH_DIGESTS_PREVIEW';
export const ACTION_FETCH_DAILY_EVENTS = 'ACTION_FETCH_DAILY_EVENTS';
export const ACTION_FETCH_IDEAS = 'ACTION_FETCH_IDEAS';
export const ACTION_FETCH_DIGEST_ITEM = 'ACTION_FETCH_DIGEST_ITEM';
export const ACTION_FETCH_EVENT_ITEM = 'ACTION_FETCH_EVENT_ITEM';
export const ACTION_FETCH_IDEA_ITEM = 'ACTION_FETCH_IDEA_ITEM';
export const ACTION_FETCH_TAGS = 'ACTION_FETCH_TAGS';

// mutations
export const MUTATION_SET_DIGESTS_PREVIEW = 'MUTATION_SET_DIGESTS_PREVIEW';
export const MUTATION_SET_DAILY_EVENTS = 'MUTATION_SET_DAILY_EVENTS';
export const MUTATION_SET_IDEAS = 'MUTATION_SET_IDEAS';
export const MUTATION_SET_DIGEST_ITEM = 'MUTATION_SET_DIGEST_ITEM';
export const MUTATION_SET_EVENT_ITEM = 'MUTATION_SET_EVENT_ITEM';
export const MUTATION_SET_IDEA_ITEM = 'MUTATION_SET_IDEA_ITEM';
export const MUTATION_SET_TAGS = 'MUTATION_SET_TAGS';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';

// getters
export const GETTER_DIGESTS_PREVIEW = 'GETTER_DIGESTS_PREVIEW';
export const GETTER_DAILY_EVENTS = 'GETTER_DAILY_EVENTS';
export const GETTER_IDEAS = 'GETTER_IDEAS';
export const GETTER_DIGEST_ITEM = 'GETTER_DIGEST_ITEM';
export const GETTER_EVENT_ITEM = 'GETTER_EVENT_ITEM';
export const GETTER_IDEA_ITEM = 'GETTER_IDEA_ITEM';
export const GETTER_TAGS = 'GETTER_TAGS';
