import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_CODE_SENT_AT,
  MUTATION_SET_IS_LOADING,
} from '../types';
import { PhoneConfirmationCodeInfoMapper } from '../../../mappers/phone-confirmation-code-info-mapper';
import { ConfirmationCode } from '../../../entities/confirmation-code';

export class GetPhoneConfirmationCodeInfo extends Action {
  _api;
  _errorHandler;

  constructor({ identifyApi, errorHandler }) {
    super();

    this._api = identifyApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await GetPhoneConfirmationCodeInfo._withLoading(async () => {
      try {
        const phoneConfirmationCodeInfoMapper = new PhoneConfirmationCodeInfoMapper(
          (opts) => new ConfirmationCode(opts),
        );

        const { codeSentAt } = await this._api.getPhoneConfirmationCodeInfo(
          phoneConfirmationCodeInfoMapper.mapGetPhoneConfirmationCodeInfo,
        );

        commit(MUTATION_SET_CODE_SENT_AT, { codeSentAt });
      } catch (e) {
        this._errorHandler.handle(e, 'while execute GetPhoneConfirmationCodeInfo');
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
