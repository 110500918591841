import { Action } from '@amarkets/atlas/esm/lib/action';
import { Totp } from '../../../entities/totp';
import { TotpMapper } from '../../../mappers/totp-data-mapper';
import {
  MUTATION_SET_TOTP,
  MUTATION_SET_IS_LOADING_TOTP,
} from '../types';

export class GenerateTotp extends Action {
  _api;

  constructor({ twoFactorApi }) {
    super();
    this._api = twoFactorApi;
  }

  async execute({ commit }, { onSuccess = () => {} } = {}) {
    await GenerateTotp._withLoading(async () => {
      try {
        const mapper = new TotpMapper((opts) => new Totp(opts));
        const totp = await this._api.generateTotp({ qrSize: 200 }, mapper.mapTotp);

        commit(MUTATION_SET_TOTP, totp);
        onSuccess();
      } catch (e) {
        console.error(e);
      }
    }, commit, MUTATION_SET_IS_LOADING_TOTP);
  }
}
