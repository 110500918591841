import { PAYMENT_METHOD_NAMES, PAYMENT_STATUS_NAMES } from '@/constants';
import { PaymentMethod } from './payment-method';

export class PaymentMethodAdditional extends PaymentMethod {
  static SCHEMA_ACARD = {
    ME: 'me',
    GLOBAL: 'global',
  };

  static STATUS_TYPES = {
    HOLD: 'hold',
    ERROR: 'error',
    PROGRESS: 'progress',
    SUCCESS: 'success',
  };

  constructor({
    id,
    type,
    status,
    iconUrl,
    deletable,
    editable,
    comment,
    faCommentTranslation,
    ...attributes
  }) {
    super({ type, id, ...attributes });

    Object.assign(this, {
      status: PaymentMethodAdditional._getStatus(status),
      titleCard: PaymentMethodAdditional._getTitle({ type, ...attributes }),
      iconUrl,
      allowedActions: {
        editable,
        deletable,
      },
      comment,
      faCommentTranslation,
    });
  }

  static _getStatus(status) {
    return new Map([
      [PAYMENT_STATUS_NAMES.DRAFT, {
        type: PaymentMethodAdditional.STATUS_TYPES.HOLD,
        text: 'paymentMethod.status.draft',
        iconName: 'edit',
      }],
      [PAYMENT_STATUS_NAMES.REJECTED, {
        type: PaymentMethodAdditional.STATUS_TYPES.ERROR,
        text: 'paymentMethod.status.rejected',
      }],
      [PAYMENT_STATUS_NAMES.REQUESTED, {
        type: PaymentMethodAdditional.STATUS_TYPES.PROGRESS,
        text: 'paymentMethod.status.requested',
      }],
      [PAYMENT_STATUS_NAMES.VERIFIED, {
        type: PaymentMethodAdditional.STATUS_TYPES.SUCCESS,
        text: 'paymentMethod.status.verified',
      }],
    ]).get(status);
  }

  static _getTitle({ type, ...attributes }) {
    const spareNumber = attributes.number ?? '-';

    return new Map([
      [PAYMENT_METHOD_NAMES.payment_method_card, {
        title: attributes?.paymentProvider ?? 'paymentMethod.bankCard',
        number: PaymentMethodAdditional._truncateNumber(spareNumber),
      }],
      [PAYMENT_METHOD_NAMES.payment_method_eps, {
        title: attributes?.paymentProvider ?? 'paymentMethod.onlineWallet',
        number: spareNumber,
      }],
      [PAYMENT_METHOD_NAMES.payment_method_bank, {
        title: attributes?.name ?? 'paymentMethod.bankTransfer',
        number: spareNumber,
      }],
      [PAYMENT_METHOD_NAMES.payment_method_crypto, {
        title: attributes?.currency ?? 'paymentMethod.cryptoWallet',
        number: spareNumber,
      }],
      [PAYMENT_METHOD_NAMES.payment_method_acard, {
        title: 'paymentMethod.ACARD.title',
        number: PaymentMethodAdditional._getAcardNumber(attributes),
      }],
    ]).get(type);
  }

  static _truncateNumber(number = '') {
    const numberLength = number?.length;

    if (numberLength > 4) {
      return `${number.substring(0, 6)}** **** ${number.substring(numberLength - 4)}`.replace(/(\d{4})/g, '$1 ');
    }

    return number;
  }

  static _getAcardNumber({ accountDetails }) {
    let number;

    if (accountDetails?.schema === PaymentMethodAdditional.SCHEMA_ACARD.ME) {
      number = PaymentMethodAdditional._truncateNumber(accountDetails?.data?.cardNumber);
    } else if (accountDetails?.schema === PaymentMethodAdditional.SCHEMA_ACARD.GLOBAL) {
      number = accountDetails?.data?.bankAccountNumber;
    }

    return number;
  }
}
