import { DDate } from '@amarkets/atlas/esm/lib/d-date';
import { DDateLocalized } from '@amarkets/atlas/esm/lib/d-date-localized';

export class IdeaItem {
  constructor({
    id,
    title,
    content,
    image,
    customImage,
    tp,
    sl,
    action,
    actionValue,
    timestamp,
    locale,
  }) {
    const currentDate = new Date().getTime();

    Object.assign(this, {
      id,
      title,
      content,
      image,
      customImage,
      tp,
      sl,
      action,
      actionValue,
      formattedTime: new DDate(timestamp * 1000).format('HH:mm'),
      timeFromNow: new DDateLocalized(currentDate).from(timestamp * 1000, locale),
    });
  }
}
