export default {
  isLoading: false,
  usedPhone: null,
  errorMessage: '',
  phoneOrderId: null,
  attempts: 0,
  codeSentAt: 0,
  isMobile: true,
  verificationMethod: '',
};
