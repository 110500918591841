import { Action } from '@amarkets/atlas/esm/lib/action';
import { isFunction } from '@amarkets/atlas/esm/lib/is-function';
import { AccountPassword } from '../entities/trading-account-password';
import { AccountPasswordMapper } from '../mappers/trading-account-password-mapper';

export class ChangeAccountPassword extends Action {
  _api;
  _errorHandler;
  _uiNotifier;
  _i18n;

  constructor({ accountApi, errorHandler, uiNotifier, i18n }) {
    super();

    this._api = accountApi;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute(_, { accountUid, onSuccess }) {
    try {
      const accountPasswordMapper = new AccountPasswordMapper((opts) => new AccountPassword(opts));
      const newAccountPasswordData = await this._api.changeTradingAccountPassword(
        { accountUid }, (data) => accountPasswordMapper.mapAccountPassword(data),
      );
      this._uiNotifier.success(this._i18n.global.t('notification.success.newPasswordHasBeenSent'));

      if (isFunction(onSuccess)) onSuccess(newAccountPasswordData);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute ChangeAccountPassword');
    }
  }
}
