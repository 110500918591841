import { ACTION_GET_ACCOUNTS, ACTION_GET_PAYMENT_METHODS } from '../types';
import { FetchAccounts } from './fetch-accounts';
import { FetchPaymentMethods } from './fetch-payment-methods';

export default function ({
  apiServices,
  errorHandler,
}) {
  const { depositApi } = apiServices;
  const fetchAccounts = new FetchAccounts({
    depositApi,
    errorHandler,
  });
  const fetchPaymentMethods = new FetchPaymentMethods({
    depositApi,
    errorHandler,
  });

  return {
    [ACTION_GET_ACCOUNTS]: fetchAccounts.execute.bind(fetchAccounts),
    [ACTION_GET_PAYMENT_METHODS]: fetchPaymentMethods.execute.bind(fetchPaymentMethods),
  };
}
