import { SETTINGS_ROUTES } from '@/constants';

export const settingsRoutes = [
  {
    path: SETTINGS_ROUTES.OPEN_ACCOUNT.PATH,
    component: () => import('@/views/page-open-account/page-open-account/page-open-account.vue'),
    children: [
      // Пока дорабатывают страницу, попросили скрыть
      // {
      //   path: '',
      //   name: SETTINGS_ROUTES.OPEN_ACCOUNT.NAME,
      //   component: () => import(
      //     '@/views/page-open-account/page-open-account-simplified/page-open-account-simplified.vue'
      //   ),
      // },
      {
        path: SETTINGS_ROUTES.OPEN_ACCOUNT.PATH,
        name: SETTINGS_ROUTES.OPEN_ACCOUNT.NAME,
        component: () => import(
          '@/views/page-open-account/page-open-account-edit/page-open-account-edit.vue'
        ),
      },
      {
        path: SETTINGS_ROUTES.OPEN_ACCOUNT_SUCCESS.PATH,
        name: SETTINGS_ROUTES.OPEN_ACCOUNT_SUCCESS.NAME,
        component: () => import(
          '@/views/page-open-account/page-open-account-success/page-open-account-success.vue'
        ),
      },
    ],
  },
  {
    path: SETTINGS_ROUTES.OPEN_ACCOUNT_DEMO.PATH,
    component: () => import('@/views/page-open-account/page-open-account-demo/page-open-account-demo.vue'),
    children: [
      {
        path: SETTINGS_ROUTES.OPEN_ACCOUNT_DEMO.PATH,
        name: SETTINGS_ROUTES.OPEN_ACCOUNT_DEMO.NAME,
        component: () => import(
          '@/views/page-open-account/page-open-account-edit/page-open-account-edit.vue'
        ),
        props: {
          isDemoAccount: true,
        },
      },
      {
        path: SETTINGS_ROUTES.OPEN_ACCOUNT_DEMO_SUCCESS.PATH,
        name: SETTINGS_ROUTES.OPEN_ACCOUNT_DEMO_SUCCESS.NAME,
        component: () => import(
          '@/views/page-open-account/page-open-account-success/page-open-account-success.vue'
        ),
        props: true,
      },
    ],
  },
];
