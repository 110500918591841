export class UserSession {
  constructor({
    isCurrentSession,
    deviceType,
    isActive,
    lastIp,
    userAgent,
    signedIn,
    lastActivity,
  }) {
    Object.assign(this, {
      isCurrentSession,
      deviceType,
      isActive,
      lastIp,
      userAgent,
      signedIn,
      lastActivity,
    });
  }
}
