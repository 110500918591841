import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_TRADES_TOTAL } from '../types';

export class FetchTradesTotal extends Action {
  _api;
  _errorHandler;

  constructor({ agoraApi, errorHandler }) {
    super();

    this._api = agoraApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, {
    userUid,
    status,
    login,
  }) {
    try {
      const { tradesTotal } = await this._api.getTradesTotal({ userUid, status, login });

      commit(MUTATION_SET_TRADES_TOTAL, tradesTotal);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchTradesTotal');
    }
  }
}
