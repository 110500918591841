import isStage from './is-stage';
import { WILDCARD_DOMAIN_LIST } from '../const';

const getDomainLevelOne = ({ iso, isStageDomain }) => {
  const environmentDomain = isStageDomain ? 'stage' : 'prod';
  const domainListByEnvironment = WILDCARD_DOMAIN_LIST[environmentDomain];

  return domainListByEnvironment[iso] || domainListByEnvironment.DEFAULT;
};

const transformOriginToKebabCase = ({ origin }) => origin.replace(/\./g, '-');

export default function generateNewAuthDomain({ iso, origin }) {
  const kebabCasedOrigin = transformOriginToKebabCase({ origin });
  const domainLevelOne = getDomainLevelOne({
    iso,
    isStageDomain: isStage(kebabCasedOrigin),
  });

  if (domainLevelOne[0] === '*') {
    return `${kebabCasedOrigin}${domainLevelOne.slice(1)}`;
  }

  return domainLevelOne;
}
