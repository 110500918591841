import { useI18n } from 'vue-i18n';
import { languageStorage } from '@amarkets/core-vue';

import { computed } from 'vue';
import { useDisplayProvide } from './use-display-provide';

export function useLanguage() {
  const { locale, t } = useI18n();
  const { isRtl } = useDisplayProvide();

  const changeLocale = (_locale) => {
    locale.value = _locale;

    languageStorage.saveLocale(_locale);
  };

  return {
    currentLocale: computed(() => locale.value),
    changeLocale,
    isRtl,
    $t: t,
  };
}
