import { StorageService } from '@amarkets/atlas/esm/lib/storage';
import { Usd } from '@/lib/values/usd';
import { TRADING_KEYS } from '@/constants/trading-keys';

const storageService = new StorageService(localStorage);

export class Trade {
  constructor({
    symbol,
    operationCmd,
    volume,
    sl,
    tp,
    swaps,
    commission,
    profit,
    openPrice,
    closePrice,
    login,
    status,
    logo,
  }) {
    const openPriceFormatted = Trade._getFormatNumber(openPrice);
    const closePriceFormatted = Trade._getFormatNumber(closePrice);

    Object.assign(this, {
      instrument: {
        symbol,
        openPrice: openPriceFormatted,
        closePrice: closePriceFormatted,
        logo,
        operationCmd,
      },
      operationCmd,
      volume: volume / 100,
      sl: Trade._getFormatNumber(sl),
      tp: Trade._getFormatNumber(tp),
      swaps: Trade._getUsd(swaps),
      rawSwaps: swaps,
      commission: Trade._getUsd(commission),
      rawCommission: commission,
      profit: {
        profitFloat: profit,
        profitUsd: Trade._getUsd(profit),
        openPrice: openPriceFormatted,
        closePrice: closePriceFormatted,
        color: Trade._profitColor(profit),
      },
      systemData: {
        login,
        status,
      },
    });
  }

  static _profitColor(profit) {
    const isNegativeProfit = Trade._checkProfit(profit);
    return isNegativeProfit ? 'error-70' : 'success-80';
  }

  static _checkProfit(number) {
    return Math.sign(number) === -1;
  }

  static _getUsd(number) {
    const activeTradingAccount = storageService.get(TRADING_KEYS.ACTIVE_TRADING_ACCOUNT);

    return new Usd({
      value: number,
      currency: activeTradingAccount ? activeTradingAccount.currency : 'USD',
      precision: 2,
    }).currency.format();
  }

  static _getFormatNumber(number) {
    return number.toLocaleString('en-EN', { maximumFractionDigits: 5 });
  }
}
