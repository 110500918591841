import { merge } from '@amarkets/atlas/esm/lib/merge';

import {
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_STATE_TO_DEFAULT,
  MUTATION_SET_USER_DOCUMENTS,
  MUTATION_SET_GETID_CONFIG,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    merge(state, defaultState);
  },
  [MUTATION_SET_USER_DOCUMENTS](state, documents) {
    state.documents = documents;
  },
  [MUTATION_SET_GETID_CONFIG](state, getidConfig) {
    state.getidConfig = getidConfig;
  },
};
