import { ACTION_FETCH_USER_DOCUMENTS, ACTION_FETCH_GETID_CONFIG } from '../types';
import { FetchUserDocuments } from './fetch-user-documents';
import { FetchGetidConfig } from './fetch-getid-config';

export default function ({
  apiServices,
  errorHandler,
}) {
  const { identifyApi, officeApi } = apiServices;

  const fetchAllDocuments = new FetchUserDocuments({
    identifyApi,
    errorHandler,
  });
  const fetchGetidConfig = new FetchGetidConfig({ officeApi, errorHandler });

  return {
    [ACTION_FETCH_USER_DOCUMENTS]: fetchAllDocuments.execute.bind(fetchAllDocuments),
    [ACTION_FETCH_GETID_CONFIG]: fetchGetidConfig.execute.bind(fetchGetidConfig),
  };
}
