import {
  MUTATION_SET_IS_LOADING_TOTP,
  MUTATION_SET_TOTP,
  MUTATION_SET_MESSAGE_VALIDATION_ERROR,
  MUTATION_SET_ENABLE_2FA_LOADING,
  MUTATION_SET_STATE_TO_DEFAULT,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_IS_LOADING_TOTP](state, isLoading) {
    state.isLoadingTotp = isLoading;
  },
  [MUTATION_SET_TOTP](state, totp) {
    state.totp = totp;
  },
  [MUTATION_SET_MESSAGE_VALIDATION_ERROR](state, msg) {
    state.validationErrorMessage = msg;
  },
  [MUTATION_SET_ENABLE_2FA_LOADING](state, isLoading) {
    state.enable2faLoading = isLoading;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    Object.assign(state, defaultState);
  },
};
