import {
  MUTATION_SET_ACCOUNT_GROUPS,
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_OPENED_ACCOUNT,
  MUTATION_SET_MESSAGE_ERROR,
} from './types';

export default {
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_ACCOUNT_GROUPS](state, accountGroups) {
    state.accountGroups = accountGroups;
  },
  [MUTATION_SET_MESSAGE_ERROR](state, error) {
    state.businessError = error;
  },
  [MUTATION_SET_OPENED_ACCOUNT](state, openedAccount) {
    state.openedAccount = openedAccount;
  },
};
