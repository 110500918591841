import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class IdeasMapper extends Mapper {
  _ideasFactory;

  constructor(ideasFactory) {
    super();

    this._ideasFactory = ideasFactory;
  }

  mapIdeas = (response) => ({
    data: response.data.data.map(
      ({ id, type, attributes }) => IdeasMapper._getResult({ id, type, ...attributes }, this._ideasFactory)),
    meta: response.data.meta.page,
  });
}
