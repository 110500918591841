export class UserDocuments {
  constructor({
    documentType,
    dateOfExpiry,
    documentNumber,
    status,
    createdAt,
  }) {
    Object.assign(this, {
      documentType,
      dateOfExpiry,
      documentNumber,
      status,
      createdAt,
    });
  }
}
