import { Action } from '@amarkets/atlas/esm/lib/action';
import { Account } from '../entities/account';
import { AllAccountsMapper } from '../mappers/all-accounts-mapper';
import {
  MUTATION_SET_IS_ALL_ACCOUNTS_LOADING,
  MUTATION_SET_ALL_ACCOUNTS,
} from '../types';

export class FetchAllAccounts extends Action {
  _api;
  _errorHandler;

  constructor({ agoraApi, errorHandler }) {
    super();

    this._api = agoraApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, uuid) {
    await FetchAllAccounts._withLoading(async () => {
      try {
        const accountsMapper = new AllAccountsMapper((opts) => new Account(opts));
        const allAccounts = await this._api.getAllAccounts(
          uuid, (data) => accountsMapper.mapAccounts(data),
        );

        commit(MUTATION_SET_ALL_ACCOUNTS, allAccounts);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchAllAccounts');
      }
    }, commit, MUTATION_SET_IS_ALL_ACCOUNTS_LOADING);
  }
}
