import {
  MUTATION_SET_IS_LOADING_PASSWORD,
  MUTATION_SET_MESSAGE_ERROR,
} from './types';

export default {
  [MUTATION_SET_IS_LOADING_PASSWORD](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_MESSAGE_ERROR](state, msg) {
    state.messageError = msg;
  },
};
