import { Guard } from '@amarkets/atlas/esm/lib/guard';

export const getLocationWindow = () => {
  const _location = window.location;

  if (window.location) Guard.notExistingObjValue(window.location, 'origin', '_location');

  return {
    origin: _location.origin,
  };
};
