import { Action } from '@amarkets/atlas/esm/lib/action';
import { HTTP_ERRORS } from '@/constants';
import { MUTATION_SET_IS_LOADING, MUTATION_SET_TIMER_SECONDS } from '../types';

export class ConfirmEmailCode extends Action {
  _api;
  _handleError;
  _uiNotifier;
  _i18n;

  constructor({
    identifyApi,
    handleError,
    uiNotifier,
    i18n,
  }) {
    super();

    this._api = identifyApi;
    this._handleError = handleError;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute({ commit }, {
    code,
    onSuccess = () => {},
  } = {}) {
    await ConfirmEmailCode._withLoading(async () => {
      try {
        await this._api.confirmEmailCode({ code });
        this._uiNotifier.success(this._i18n.global.t('verification.email.confirmed'));
        onSuccess();
      } catch (error) {
        if (error?.response?.data.errors[0]?.meta?.cooldown) {
          commit(MUTATION_SET_TIMER_SECONDS, error?.response?.data.errors[0]?.meta?.cooldown);
        }

        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute ConfirmEmailCode',
          statusCodesForCustomBehavior: [HTTP_ERRORS.TOO_MANY_REQUESTS],
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
