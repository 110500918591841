import { SERVICES_ROUTES } from '@/constants';

export const servicesRoutes = [
  {
    path: SERVICES_ROUTES.PREMIUM_ANALYTICS.PATH,
    component: () => import(
      /* webpackChunkName: "page-premium-analytics" */
      '@/views/page-premium-analytics/page-premium-analytics/page-premium-analytics.vue'
    ),
    children: [
      {
        path: SERVICES_ROUTES.PREMIUM_ANALYTICS.PATH,
        name: SERVICES_ROUTES.PREMIUM_ANALYTICS.NAME,
        component: () => import(
          /* webpackChunkName: "page-premium-analytics-main" */
          '@/views/page-premium-analytics/page-premium-analytics-main/page-premium-analytics-main.vue'
        ),
      },
      {
        path: SERVICES_ROUTES.PREMIUM_ANALYTICS_DIGESTS.PATH,
        name: SERVICES_ROUTES.PREMIUM_ANALYTICS_DIGESTS.NAME,
        component: () => import(
          /* webpackChunkName: "page-premium-analytics-digests" */
          '@/views/page-premium-analytics/page-premium-analytics-digests/page-premium-analytics-digests.vue'
        ),
      },
      {
        path: SERVICES_ROUTES.PREMIUM_ANALYTICS_EVENTS.PATH,
        name: SERVICES_ROUTES.PREMIUM_ANALYTICS_EVENTS.NAME,
        component: () => import(
          /* webpackChunkName: "page-premium-analytics-events" */
          '@/views/page-premium-analytics/page-premium-analytics-events/page-premium-analytics-events.vue'
        ),
      },
      {
        path: SERVICES_ROUTES.PREMIUM_ANALYTICS_IDEAS.PATH,
        name: SERVICES_ROUTES.PREMIUM_ANALYTICS_IDEAS.NAME,
        component: () => import(
          /* webpackChunkName: "page-premium-analytics-ideas" */
          '@/views/page-premium-analytics/page-premium-analytics-ideas/page-premium-analytics-ideas.vue'
        ),
      },
      {
        path: SERVICES_ROUTES.PREMIUM_ANALYTICS_DIGEST.PATH,
        name: SERVICES_ROUTES.PREMIUM_ANALYTICS_DIGEST.NAME,
        component: () => import(
          /* webpackChunkName: "page-premium-analytics-digest" */
          '@/views/page-premium-analytics/page-premium-analytics-digest/page-premium-analytics-digest.vue'
        ),
        props: true,
      },
      {
        path: SERVICES_ROUTES.PREMIUM_ANALYTICS_EVENT.PATH,
        name: SERVICES_ROUTES.PREMIUM_ANALYTICS_EVENT.NAME,
        component: () => import(
          /* webpackChunkName: "page-premium-analytics-event" */
          '@/views/page-premium-analytics/page-premium-analytics-event/page-premium-analytics-event.vue'
        ),
        props: true,
      },
      {
        path: SERVICES_ROUTES.PREMIUM_ANALYTICS_IDEA.PATH,
        name: SERVICES_ROUTES.PREMIUM_ANALYTICS_IDEA.NAME,
        component: () => import(
          /* webpackChunkName: "page-premium-analytics-idea" */
          '@/views/page-premium-analytics/page-premium-analytics-idea/page-premium-analytics-idea.vue'
        ),
        props: true,
      },
    ],
  },
];
