import { UserVerification } from './user-verification';

export class EmailVerificationState extends UserVerification {
  constructor(emailNumberVerified) {
    super(emailNumberVerified);

    this._status = this.emailVerificationState;
  }

  get emailVerificationState() {
    return this._verifiedStatus
      ? UserVerification.VERIFICATION_STATE.VERIFIED
      : UserVerification.VERIFICATION_STATE.NOT_VERIFIED;
  }
}
