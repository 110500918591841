import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class GetidConfigMapper extends Mapper {
  _getidConfig;

  constructor(getidConfig) {
    super();

    this._getidConfig = getidConfig;
  }

  mapGetidConfig = (response) => GetidConfigMapper._getResult(
    response.data.attributes, this._getidConfig,
  );
}
