export class Card {
  static _MODEL = {
    number: '',
    expirationMonth: '',
    expirationYear: '',
    cardholderFullName: '',
    cardType: '',
    text: '',
  };

  constructor({
    number,
    expirationMonth,
    expirationYear,
    cardholderFullName,
    cardType,
  }) {
    Object.assign(this, {
      number: number ?? Card._MODEL.number,
      expirationMonth: expirationMonth ?? Card._MODEL.expirationMonth,
      expirationYear: expirationYear ?? Card._MODEL.expirationYear,
      cardholderFullName: cardholderFullName ?? Card._MODEL.cardholderFullName,
      cardType: cardType ?? Card._MODEL.cardType,
      text: Card._MODEL.text,
    });
  }
}
