import { EmailVerificationState, PhoneVerificationState } from '@/lib/entities';

export class User {
  constructor({
    email,
    phoneOrder,
    emailIdentity,
    phoneIdentity,
    documents,
    customerAgreementRequired,
    uid,
    givenName,
    familyName,
    firstName,
    lastName,
    name,
  }) {
    Object.assign(this, {
      phoneNumber: phoneIdentity.number || phoneOrder.number,
      emailVerified: !!emailIdentity.uid,
      phoneNumberVerified: !!phoneIdentity.number,
      verificationState: {
        phone: new PhoneVerificationState({
          phoneNumberVerified: !!phoneIdentity.number,
          emailVerified: !!emailIdentity.uid,
        }),
        email: new EmailVerificationState(!!emailIdentity.uid),
      },
      userUid: uid,
      fullName: name ?? User._getFullName({
        firstName: firstName ?? givenName,
        lastName: lastName ?? familyName,
      }),
      email,
      customerAgreementRequired,
      documents,
      lastName,
      firstName,
    });
  }

  static _getFullName({ firstName = '', lastName = '' }) {
    return `${firstName} ${lastName}`;
  }
}
