import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class OpenedAccountMapper extends Mapper {
  _openedAccount;

  constructor(openedAccount) {
    super();

    this._openedAccount = openedAccount;
  }

  mapOpenedAccount = (response) => OpenedAccountMapper._getResult(
    response.data.attributes, this._openedAccount,
  );
}
