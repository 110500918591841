import {
  GETTER_PAYMENT_METHODS,
  GETTER_IS_LOADING_STATUS,
  GETTER_PAYMENT_METHOD_BY_ID,
  GETTER_PAYMENT_METHOD_BY_TYPE,
  GETTER_PAYMENT_METHODS_TYPES,
  GETTER_IS_VERIFICATION_REQUIRED,
  GETTER_IS_LOADING_TYPES,
} from './types';

export default {
  [GETTER_PAYMENT_METHODS]: ({ paymentMethods }) => paymentMethods,
  [GETTER_PAYMENT_METHODS_TYPES]: ({ paymentMethodsTypes }) => paymentMethodsTypes,
  [GETTER_IS_VERIFICATION_REQUIRED]: ({ paymentMethodsTypes }) => ({ paymentType }) => {
    const foundPaymentType = paymentMethodsTypes.find((type) => type.id === paymentType);
    return foundPaymentType?.verificationRequired ?? false;
  },
  [GETTER_PAYMENT_METHOD_BY_ID]: ({ paymentMethods }) => (id) => paymentMethods.find((method) => method.id === id),
  [GETTER_PAYMENT_METHOD_BY_TYPE]: ({ paymentMethods }) => ({ type }) => paymentMethods.filter((method) => {
    if (type === 'all') return true;
    return method.type === type;
  }),
  [GETTER_IS_LOADING_STATUS]: ({ isLoading }) => isLoading,
  [GETTER_IS_LOADING_TYPES]: ({ isLoadingTypes }) => isLoadingTypes,
};
