import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { ClickOutside } from 'vuetify/directives';
import * as components from 'vuetify/components';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import { themes, aliases, iconSvg } from '@amarkets/ui-kit-next';
import { LOCALES } from '@/constants';

export const useVuetify = ({ i18n }) => {
  const vuetify = createVuetify({
    directives: {
      ClickOutside,
    },
    components,
    theme: {
      defaultTheme: 'main',
      themes,
      variations: false,
    },
    icons: {
      defaultSet: 'custom',
      aliases,
      sets: {
        custom: iconSvg,
      },
    },
    display: {
      // we have to set a min-width for breakpoints in Vuetify 3
      mobileBreakpoint: 'md',
      thresholds: {
        xs: 0,
        sm: 391,
        md: 1024,
        lg: 1440,
        xl: 1980,
      },
    },
    rtl: LOCALES.FA === i18n.locale,
    lang: {
      t: (key, ...params) => i18n.t(key, params),
    },
    locale: {
      adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
  });

  return vuetify;
};
