import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class ConfirmationCodeMapper extends Mapper {
  _sendConfirmationCodeDataFactory;

  constructor(sendConfirmationCodeDataFactory) {
    super();

    this._sendConfirmationCodeDataFactory = sendConfirmationCodeDataFactory;
  }

  mapConfirmationCode = (response) => ConfirmationCodeMapper._getResult({
    attempts: response.data.attributes.attempts,
    codeSentAt: response.data.attributes.codeSentAt || response.data.attributes.updatedAt,
    otpDeliveryMethod: response.data.attributes.otpDeliveryMethod,
  }, this._sendConfirmationCodeDataFactory);
}
