import Intercom from '@intercom/messenger-js-sdk';

export const initIntercom = (intercomAppId, user) => {
  if (!intercomAppId) {
    return;
  }

  Intercom({
    app_id: intercomAppId,
    user_id: user.id,
    name: user.name,
    email: user.email,
    created_at: user.createdAt,
  });
};
