export const STORE_MODULE_ENABLE_2FA = 'STORE_MODULE_ENABLE_2FA';

export const STATUS_2FA_ENABLED = 'enabled';
export const STATUS_2FA_FORBIDDEN = 'forbidden';
export const STATUS_2FA_AVAILABLE = 'available';

export const HTTP_ERRORS = {
  VALIDATION_ERROR: 422,
};

export const MOBILE_OS_NAMES = {
  ANDROID: 'Android OS',
  IOS: 'iOS',
};

export const STORE_NAMES_BY_OS = {
  [MOBILE_OS_NAMES.IOS]: 'appStore',
  [MOBILE_OS_NAMES.ANDROID]: 'googlePlay',
  other: 'other',
};

export const AUTHENTICATOR_TYPES = {
  GOOGLE: 'Google',
  AUTHY: 'Authy',
};

export const AUTHENTICATOR_LINKS = {
  [AUTHENTICATOR_TYPES.GOOGLE]: {
    appStore: 'https://apps.apple.com/app/google-authenticator/id388497605',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    other: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  },
  [AUTHENTICATOR_TYPES.AUTHY]: {
    appStore: 'https://apps.apple.com/app/twilio-authy/id494168017',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.authy.authy',
    other: 'https://authy.com/download/',
  },
};

export const CMP_NAMES = {
  1: 'step-1',
  2: 'step-2',
  3: 'step-3',
};
