import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class AccountPasswordMapper extends Mapper {
  _accountPasswordData;

  constructor(accountPasswordData) {
    super();

    this._accountPasswordData = accountPasswordData;
  }

  mapAccountPassword = (response) => AccountPasswordMapper._getResult(
    response.data.attributes, this._accountPasswordData,
  );
}
