import {
  STATUS_2FA_ENABLED,
  STATUS_2FA_FORBIDDEN,
  STATUS_2FA_AVAILABLE,
} from '../const';

export class TwoFactorStatus {
  _status;

  constructor(status) {
    this._status = status;
  }

  get isEnabled() {
    return this._status === STATUS_2FA_ENABLED;
  }
  get isForbidden() {
    return this._status === STATUS_2FA_FORBIDDEN;
  }
  get isAvailable() {
    return this._status === STATUS_2FA_AVAILABLE;
  }
}
