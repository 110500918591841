// actions
export const ACTION_CREATE_PHONE_ORDER = 'ACTION_CREATE_PHONE_ORDER';
export const ACTION_SEND_CONFIRMATION_CODE = 'ACTION_SEND_CONFIRMATION_CODE';
export const ACTION_CONFIRM_PHONE_CODE = 'ACTION_CONFIRM_PHONE_CODE';
export const ACTION_GET_PHONE_CONFIRMATION_CODE_INFO = 'ACTION_GET_PHONE_CONFIRMATION_CODE_INFO';

// mutations
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_USED_PHONE = 'MUTATION_SET_USED_PHONE';
export const MUTATION_SET_PHONE_ORDER_ID = 'MUTATION_SET_PHONE_ORDER_ID';
export const MUTATION_SET_MESSAGE_ERROR = 'MUTATION_SET_MESSAGE_ERROR';
export const MUTATION_SET_ATTEMPTS = 'MUTATION_SET_ATTEMPTS';
export const MUTATION_SET_CODE_SENT_AT = 'MUTATION_SET_CODE_SENT_AT';
export const MUTATION_SET_IS_MOBILE = 'MUTATION_SET_IS_MOBILE';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';
export const MUTATION_SET_VERIFICATION_METHOD = 'MUTATION_SET_VERIFICATION_METHOD';

// getters
export const GETTER_IS_LOADING_STATUS = 'GETTER_IS_LOADING_STATUS';
export const GETTER_USED_PHONE = 'GETTER_USED_PHONE';
export const GETTER_PHONE_ORDER_ID = 'GETTER_PHONE_ORDER_ID';
export const GETTER_MESSAGE_ERROR = 'GETTER_MESSAGE_ERROR';
export const GETTER_ATTEMPTS = 'GETTER_ATTEMPTS';
export const GETTER_CODE_SENT_AT = 'GETTER_CODE_SENT_AT';
export const GETTER_IS_MOBILE = 'GETTER_IS_MOBILE';
export const GETTER_VERIFICATION_METHOD = 'GETTER_VERIFICATION_METHOD';
