import { HttpClient } from '@amarkets/atlas/esm/lib/http-client';
import { curry } from '@amarkets/atlas/esm/lib/curry';

const injectAuthHeader = curry(
  (getAccessToken, reqConfig) => {
    const { headers } = reqConfig;
    headers.Authorization = `Bearer ${getAccessToken()}`;

    return reqConfig;
  },
);

const setAcceptLanguage = curry((i18n, reqConfig) => {
  const { headers } = reqConfig;
  headers['Accept-Language'] = i18n.global.locale.value;

  return reqConfig;
});

const onTokenFailedHandle = (jwtService, ssoClient) => () => {
  jwtService.removeTokens();
  ssoClient.authorize();
};

export const createHttpClient = ({ jwtService, ssoClient, i18n }) => (
  new HttpClient({
    reqInterceptorsSuccess: [
      injectAuthHeader(() => jwtService.accessToken),
      setAcceptLanguage(i18n),
    ],
    onTokenExpired: jwtService.updateToken.bind(jwtService),
    onTokenFail: onTokenFailedHandle(jwtService, ssoClient),
  })
);
