import {
  GETTER_LOADING_TOTP,
  GETTER_TOTP,
  GETTER_VALIDATION_ERROR_MESSAGE,
  GETTER_ENABLE_2FA_LOADING,
} from './types';

export default {
  [GETTER_TOTP]: ({ totp }) => totp,
  [GETTER_LOADING_TOTP]: ({ isLoadingTotp }) => isLoadingTotp,
  [GETTER_ENABLE_2FA_LOADING]: ({ enable2faLoading }) => enable2faLoading,
  [GETTER_VALIDATION_ERROR_MESSAGE]: ({ validationErrorMessage }) => validationErrorMessage,
};
