import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class TotpMapper extends Mapper {
  _totpDataFactory;

  constructor(totpDataFactory) {
    super();

    this._totpDataFactory = totpDataFactory;
  }

  mapTotp = (response) => TotpMapper._getResult({
    secret: response.data.attributes.secret,
    signature: response.data.attributes.signature,
    qr: response.data.attributes.qr,
    generatedAt: response.data.attributes.generatedAt,
  }, this._totpDataFactory);
}
