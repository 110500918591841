import { parseIdToken } from '@amarkets/atlas/esm/lib/parse-id-token';
import FacadeGlobal from '@/plugins/store/facades/facade-global';

export const setUserDataFromIdToken = ({ idToken, store }) => {
  const facadeGlobal = new FacadeGlobal(store);
  const { walletId, ctry } = parseIdToken(idToken);

  facadeGlobal.setUserWalletId(walletId);
  facadeGlobal.setUserCountry(ctry);
};
