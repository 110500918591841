import { ACTION_FETCH_CASHBACK } from '../types';
import { FetchAccountCashback } from './fetch-account-cashback';

export default function ({ apiServices, errorHandler }) {
  const {
    accountApi,
  } = apiServices;

  const fetchAccountCashback = new FetchAccountCashback({ accountApi, errorHandler });

  return {
    [ACTION_FETCH_CASHBACK]: fetchAccountCashback.execute.bind(fetchAccountCashback),
  };
}
