<template>
  <v-main class="text-center align-center">
    <layout-init-loading v-if="isLoadingVisible" />
    <layout-init-error v-if="isErrorVisible" />
  </v-main>
</template>

<script>
import LayoutInitError from './layout-init-error.vue';
import LayoutInitLoading from './layout-init-loading.vue';

export default {
  name: 'layout-init',
  components: {
    LayoutInitError,
    LayoutInitLoading,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isErrorVisible() {
      return (this.isLoading && this.isError) || this.isError;
    },
    isLoadingVisible() {
      return !this.isErrorVisible && this.isLoading;
    },
  },
};
</script>
