import { Action } from '@amarkets/atlas/esm/lib/action';
import { GeneralItem } from '../entities/general-item';
import { IdeasMapper } from '../mappers/ideas-mapper';
import { MUTATION_SET_IDEAS } from '../types';

export class FetchIdeas extends Action {
  _api;
  _errorHandler;

  constructor({ premiumAnalyticsApi, errorHandler }) {
    super();
    this._api = premiumAnalyticsApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, {
    pageNumber = 1, pageSize = 6, filterTag, filterPost, locale,
  } = {}) {
    try {
      const ideaMapper = new IdeasMapper((opts) => new GeneralItem({ ...opts, locale }));
      const ideas = await this._api.getIdeasPreview({
        pageNumber,
        pageSize,
        filterTag,
        filterPost,
      }, (data) => ideaMapper.mapIdeas(data));

      commit(MUTATION_SET_IDEAS, ideas);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchIdeas');
    }
  }
}
