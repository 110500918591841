export const STORE_MODULE_SECURITY = 'STORE_MODULE_SECURITY';

export const ROUTES = {
  SECURITY: {
    PATH: '/security',
    NAME: 'security',
  },
  ADD_SECOND_FACTOR: {
    PATH: '2fa',
    NAME: 'add-second-factor',
  },
};

export const MODAL_TYPE = {
  DISABLE: 'disable',
  HOW_IT_WORKS: 'howItWorks',
  SUCCESSFUL: 'successful',
};

export const TOGGLE_2FA_ENABLE_TYPE = 'enable';
export const TOGGLE_2FA_DISABLE_TYPE = 'disable';

export const STATUS_2FA_ENABLED = 'enabled';
export const STATUS_2FA_FORBIDDEN = 'forbidden';
export const STATUS_2FA_AVAILABLE = 'available';

export const HTTP_ERRORS = {
  VALIDATION_ERROR: 422,
};

export const MOBILE_OS_NAMES = {
  ANDROID: 'Android OS',
  IOS: 'iOS',
};

export const STORE_NAMES_BY_OS = {
  [MOBILE_OS_NAMES.IOS]: 'appStore',
  [MOBILE_OS_NAMES.ANDROID]: 'googlePlay',
};

export const GOOGLE_AUTHENTICATOR_LINKS = {
  appStore: 'https://apps.apple.com/ru/app/google-authenticator/id388497605',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
};
