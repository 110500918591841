import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_LOADING } from '../types';

export class ConfirmPhoneCode extends Action {
  _api;
  _handleError;

  constructor({ identifyApi, handleError }) {
    super();

    this._api = identifyApi;
    this._handleError = handleError;
  }

  async execute({ commit }, {
    orderId,
    code,
    onSuccess = () => {},
  } = {}) {
    await ConfirmPhoneCode._withLoading(async () => {
      try {
        await this._api.confirmPhoneCode({ orderId, code });

        onSuccess();
      } catch (error) {
        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute ConfirmPhoneCode',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
