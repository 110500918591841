import { VuexFacadeBase } from '@amarkets/atlas/esm/lib/vuex-facade-base';
import {
  STORE_MODULE_PAYMENT_METHODS,
  ACTION_FETCH_PAYMENT_METHODS,
  GETTER_IS_LOADING_STATUS,
  GETTER_PAYMENT_METHODS,
  GETTER_PAYMENT_METHOD_BY_ID,
  GETTER_PAYMENT_METHOD_BY_TYPE,
  ACTION_DELETE_PAYMENT_METHOD,
  ACTION_FETCH_PAYMENT_METHODS_TYPES,
  GETTER_PAYMENT_METHODS_TYPES,
  GETTER_IS_VERIFICATION_REQUIRED,
  GETTER_IS_LOADING_TYPES,
} from '../modules/payment-methods/types';

export class FacadePaymentMethods extends VuexFacadeBase {
  constructor(store) {
    super(store, STORE_MODULE_PAYMENT_METHODS);
  }

  fetchPaymentMethods = (payload) => this.dispatch(ACTION_FETCH_PAYMENT_METHODS, payload);
  fetchPaymentMethodsTypes = () => this.dispatch(ACTION_FETCH_PAYMENT_METHODS_TYPES);
  deletePaymentMethods = (payload) => this.dispatch(ACTION_DELETE_PAYMENT_METHOD, payload);

  paymentMethods = () => this.getters(GETTER_PAYMENT_METHODS);
  paymentMethodById = (id) => this.getters(GETTER_PAYMENT_METHOD_BY_ID, id);

  paymentMethodsTypes = () => this.getters(GETTER_PAYMENT_METHODS_TYPES);
  paymentMethodsByType = (payload) => this.getters(GETTER_PAYMENT_METHOD_BY_TYPE, payload);

  isLoading = () => this.getters(GETTER_IS_LOADING_STATUS);
  isLoadingTypes = () => this.getters(GETTER_IS_LOADING_TYPES);
  isVerificationRequiredPaymentType = (payload) => this.getters(GETTER_IS_VERIFICATION_REQUIRED, payload);
}
