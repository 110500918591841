import { merge } from '@amarkets/atlas/esm/lib/merge';

import {
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_STATE_TO_DEFAULT,
  MUTATION_SET_PAYMENT_METHODS,
  MUTATION_SET_PAYMENT_METHODS_TYPES,
  MUTATION_SET_IS_LOADING_TYPES,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    merge(state, defaultState);
  },
  [MUTATION_SET_PAYMENT_METHODS](state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  [MUTATION_SET_PAYMENT_METHODS_TYPES](state, paymentMethodsTypes) {
    state.paymentMethodsTypes = paymentMethodsTypes;
  },
  [MUTATION_SET_IS_LOADING_TYPES](state, isLoading) {
    state.isLoadingTypes = isLoading;
  },
};
