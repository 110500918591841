import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class AccountGroupsMapper extends Mapper {
  _accountGroups;

  constructor(accountGroups) {
    super();

    this._accountGroups = accountGroups;
  }

  mapAccountGroups = (response) => response.data.map((item) => (
    AccountGroupsMapper._getResult(item.attributes, this._accountGroups)));
}
