import { useDisplay, useLocale } from 'vuetify';
import { computed } from 'vue';

export function useDisplayProvide() {
  const {
    name,
    sm,
    xs,
    lgAndUp,
    mdAndUp,
    platform,
    mobile,
    width,
  } = useDisplay();
  const { isRtl } = useLocale();

  return {
    breakpointName: name,
    isBreakpointSm: sm,
    isBreakpointXs: xs,
    hasTouch: platform.value.touch,
    isMobileDevice: computed(() => mobile.value),
    isRtl,
    lgAndUp,
    mdAndUp,
    mobile,
    width,
  };
}
