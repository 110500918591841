export const STORE_MODULE_OPEN_ACCOUNT = 'STORE_MODULE_OPEN_ACCOUNT';

export const ACTION_OPEN_ACCOUNT = 'ACTION_OPEN_ACCOUNT';
export const ACTION_OPEN_DEMO_ACCOUNT = 'ACTION_OPEN_DEMO_ACCOUNT';
export const ACTION_FETCH_ACCOUNT_GROUPS = 'ACTION_FETCH_ACCOUNT_GROUPS';

export const GETTER_IS_LOADING = 'GETTER_IS_LOADING';
export const GETTER_ACCOUNT_GROUPS = 'GETTER_ACCOUNT_GROUPS';
export const GETTER_OPENED_ACCOUNT = 'GETTER_OPENED_ACCOUNT';
export const GETTER_MESSAGE_ERROR = 'GETTER_MESSAGE_ERROR';

export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_ACCOUNT_GROUPS = 'MUTATION_SET_ACCOUNT_GROUPS';
export const MUTATION_SET_OPENED_ACCOUNT = 'MUTATION_SET_OPENED_ACCOUNT';
export const MUTATION_SET_MESSAGE_ERROR = 'MUTATION_SET_MESSAGE_ERROR';
