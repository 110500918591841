import { Action } from '@amarkets/atlas/esm/lib/action';
import { PaymentMethodAdditional } from '@/lib/entities';
import {
  MUTATION_SET_PAYMENT_METHODS,
  MUTATION_SET_IS_LOADING,
} from '../types';
import { PaymentMethodMapper } from '../mappers/payment-method-mapper';

export class FetchPaymentMethods extends Action {
  _api;
  _errorHandler;

  constructor({ officeApi, errorHandler }) {
    super();

    this._api = officeApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchPaymentMethods._withLoading(async () => {
      try {
        const paymentMethodMapper = new PaymentMethodMapper((opts) => new PaymentMethodAdditional(opts));
        const paymentMethods = await this._api.getPaymentMethods(
          (data) => paymentMethodMapper.mapPaymentMethods(data),
        );

        commit(MUTATION_SET_PAYMENT_METHODS, paymentMethods);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchPaymentMethods');
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
