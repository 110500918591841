import { Usd } from '@/lib/values/usd';

export class Account {
  constructor({
    id: accountId,
    attributes: {
      wallet: {
        id,
        balance,
        currency,
      },
      dailyAccountsPnl,
      totalAccountsAmount,
      totalAccountsEquity,
    },
  }) {
    Object.assign(this, {
      id: accountId,
      walletId: id,
      dailyAccountsPnlRaw: dailyAccountsPnl,
      totalAccountsAmountRaw: totalAccountsAmount,
      walletBalance: Account.getValueWithCurrency(balance, currency),
      dailyAccountsPnl: Account.getValueWithCurrency(dailyAccountsPnl),
      totalAccountsEquity: Account.getValueWithCurrency(totalAccountsEquity + balance),
    });
  }

  static getValueWithCurrency(value, currency = 'USD') {
    return new Usd({
      precision: 2,
      value,
      currency,
    }).currency.format();
  }
}
