import { DDateLocalized } from '@amarkets/atlas/esm/lib/d-date-localized';
import { getFormattedHoursAndMinutes } from '@/lib/utils';

export class EventItem {
  constructor({
    id,
    title,
    content,
    currency,
    timestamp,
    sourceName,
    countryFlagUrl,
    actualFormatted,
    previousFormatted,
    consensusFormatted,
    consensusStatus,
    locale,
  }) {
    const timestampInMs = timestamp * 1000;
    const timeDifference = new Date(timestampInMs) - new Date();
    const { hours, minutes } = getFormattedHoursAndMinutes(timeDifference);

    Object.assign(this, {
      id,
      title,
      content,
      currency,
      sourceName,
      countryFlagUrl,
      actualFormatted,
      previousFormatted,
      consensusFormatted,
      formattedTime: `${new DDateLocalized(timestampInMs).formatToUtc('D MMM YYYY HH:mm', locale)} GMT`,
      consensusStatus,
      ...(timeDifference > 0 && {
        timeLeft: `${hours}:${minutes}`,
      }),
    });
  }
}
