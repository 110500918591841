import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class CashbackMapper extends Mapper {
  _cashbackFactory;

  constructor(cashbackFactory) {
    super();
    this._cashbackFactory = cashbackFactory;
  }

  mapCashback = (response) => CashbackMapper._getResult(
    response.data.attributes, this._cashbackFactory,
  );
}
