import { Action } from '@amarkets/atlas/esm/lib/action';
import { IdeaItem } from '../entities/idea-item';
import { SingleItemMapper } from '../mappers/single-item-mapper';
import { MUTATION_SET_IDEA_ITEM } from '../types';

export class FetchIdeaItem extends Action {
  _api;
  _errorHandler;

  constructor({ premiumAnalyticsApi, errorHandler }) {
    super();
    this._api = premiumAnalyticsApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { ideaId, locale, isPreview = true }) {
    try {
      const itemMapper = new SingleItemMapper((opts) => new IdeaItem({ ...opts, locale }));
      const apiMethod = isPreview ? this._api.getIdeaItemPreview : this._api.getIdeaItem;
      const ideaItem = await apiMethod.call(this._api, { ideaId }, (data) => itemMapper.mapItem(data));

      commit(MUTATION_SET_IDEA_ITEM, ideaItem);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchIdeaItem');
    }
  }
}
