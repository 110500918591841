// actions
export const ACTION_GENERATE_TOTP = 'ACTION_GENERATE_TOTP';
export const ACTION_ENABLE_2FA = 'ACTION_ENABLE_2FA';

// mutations
export const MUTATION_SET_2FA_STATUS = 'MUTATION_SET_2FA_STATUS';
export const MUTATION_SET_IS_LOADING_TOTP = 'MUTATION_SET_IS_LOADING_TOTP';
export const MUTATION_SET_TOTP = 'MUTATION_SET_TOTP';
export const MUTATION_SET_MESSAGE_VALIDATION_ERROR = 'MUTATION_SET_MESSAGE_VALIDATION_ERROR';
export const MUTATION_SET_ENABLE_2FA_LOADING = 'MUTATION_SET_ENABLE_2FA_LOADING';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';

// getters
export const GETTER_TOTP = 'GETTER_TOTP';
export const GETTER_LOADING_TOTP = 'GETTER_LOADING_TOTP';
export const GETTER_ENABLE_2FA_LOADING = 'GETTER_ENABLE_2FA_LOADING';
export const GETTER_VALIDATION_ERROR_MESSAGE = 'GETTER_VALIDATION_ERROR_MESSAGE';
