import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class PaymentMethodsMapper extends Mapper {
  _paymentMethodsFactory;

  constructor(paymentMethodsFactory) {
    super();

    this._paymentMethodsFactory = paymentMethodsFactory;
  }

  mapAccounts = (response) => response.data.attributes.map(
    (item) => PaymentMethodsMapper._getResult(item, this._paymentMethodsFactory),
  );
}
