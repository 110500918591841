import { Action } from '@amarkets/atlas/esm/lib/action';
import { isFunction } from '@amarkets/atlas/esm/lib/is-function';
import { OpenedAccount } from '../entities/opened-account';
import { OpenedAccountMapper } from '../mappers/opened-account-mapper';
import { MUTATION_SET_IS_LOADING } from '../types';

export class OpenDemoAccount extends Action {
  _api;
  _handleError;
  _uiNotifier;

  constructor({ officeApi, handleError, uiNotifier }) {
    super();
    this._api = officeApi;
    this._handleError = handleError;
    this._uiNotifier = uiNotifier;
  }

  async execute({ commit }, { account, onSuccess }) {
    await OpenDemoAccount._withLoading(async () => {
      try {
        const openedAccountMapper = new OpenedAccountMapper((opts) => new OpenedAccount(opts));
        const openedAccount = await this._api.openDemoAccount(
          { account }, (data) => openedAccountMapper.mapOpenedAccount(data),
        );
        if (isFunction(onSuccess)) onSuccess(openedAccount);
      } catch (error) {
        if (error.response?.data?.errors) {
          this._uiNotifier.error(error.response.data.errors);
        }
        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute OpenDemoAccount',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
