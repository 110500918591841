import { VuexFacadeBase } from '@amarkets/atlas/esm/lib/vuex-facade-base';
import {
  STORE_MODULE_AUTH,
  ACTION_LOGOUT,
  ACTION_FETCH_2FA_STATUS,
  ACTION_GET_LIST_OF_SESSIONS,
  ACTION_DELETE_ALL_SESSIONS_EXCEPT_CURRENT,
  GETTER_2FA_STATUS,
  GETTER_IS_LOADING_STATUS,
  MUTATION_SET_STATE_TO_DEFAULT,
  UPDATE_USER_LOCALE,
} from '../modules/auth/types';

export default class FacadeAuth extends VuexFacadeBase {
  constructor(store) {
    super(store, STORE_MODULE_AUTH);
  }

  logout = () => this.dispatch(ACTION_LOGOUT);
  get2faStatus = () => this.dispatch(ACTION_FETCH_2FA_STATUS);

  isLoading2fa = () => this.getters(GETTER_IS_LOADING_STATUS);
  status = () => this.getters(GETTER_2FA_STATUS);

  setStateToDefault = () => this.commit(MUTATION_SET_STATE_TO_DEFAULT);

  updateLocale = (payload) => this.dispatch(UPDATE_USER_LOCALE, payload);

  getListOfSessions = (payload) => this.dispatch(ACTION_GET_LIST_OF_SESSIONS, payload);
  deleteSessions = (payload) => this.dispatch(ACTION_DELETE_ALL_SESSIONS_EXCEPT_CURRENT, payload);
}
