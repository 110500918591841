import { AppModuleNext } from '@amarkets/atlas/esm/lib/app-module-next';
import { STORE_MODULE_SECURITY } from './const';
import { routesFactory } from './router/index';

const rootComponent = () => import(
  './components/module-root/module-root.vue'
);

const storeAsyncFactory = () => import(
  './store'
);

export default {
  module: new AppModuleNext(rootComponent, {
    storeModuleName: STORE_MODULE_SECURITY,
    storeAsyncFactory,
    routesFactory,
  }),
};
