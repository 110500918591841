import { DDate } from '@amarkets/atlas/esm/lib/d-date';
import { DDateLocalized } from '@amarkets/atlas/esm/lib/d-date-localized';
import { getFormattedHoursAndMinutes } from '@/lib/utils';

export class DigestItem {
  constructor({
    id,
    title,
    content,
    image,
    timestamp,
    events,
    locale,
    isPreview,
  }) {
    Object.assign(this, {
      id,
      title,
      content,
      bgImageStyle: {
        backgroundImage: `
          linear-gradient(180deg, rgba(0, 0, 0, 0.00) -12.22%, #131414 100%),
          url('${image}')
        `,
      },
      formattedTime: new DDateLocalized(timestamp * 1000).format('DD MMMM, YYYY', locale),
      ...(!isPreview && { events: DigestItem.getEvents(events) }),
    });
  }

  static getEvents(events) {
    return events.map((item) => {
      const { title, content, image, timestamp } = item.attributes;
      const timestampInMs = timestamp * 1000;
      const timeDifference = new Date(timestampInMs) - new Date();
      const { hours, minutes } = getFormattedHoursAndMinutes(timeDifference);

      return {
        id: item.id,
        title,
        content,
        image,
        publishTime: `${new DDate(timestampInMs).formatToUtc('HH:mm')} GMT`,
        ...(timeDifference > 0 && {
          timeLeft: `${hours}H ${minutes}M`,
        }),
      };
    });
  }
}
