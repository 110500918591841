export const STORE_MODULE_PHONE = 'STORE_MODULE_PHONE';

export const TIME_BEFORE_RESEND_MS = 60000;

export const SEND_CONFIRMATION_CODE_ATTEMPTS = {
  FIRST_METHOD: 2,
  SECOND_METHOD: 3,
};

export const VERIFICATION_METHODS = {
  SMS: 'sms',
  CALL: 'call',
  VOICE: 'voice',
};
