import {
  MUTATION_SET_IS_CASHBACK_LOADING,
  MUTATION_SET_CASHBACK,
  MUTATION_SET_STATE_TO_DEFAULT,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_IS_CASHBACK_LOADING](state, isLoading) {
    state.isCashbackLoading = isLoading;
  },
  [MUTATION_SET_CASHBACK](state, cashback) {
    state.cashback = cashback;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    Object.assign(state, defaultState);
  },
};
