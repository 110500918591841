// module name
export const STORE_MODULE_AUTH = 'STORE_MODULE_AUTH';

// actions
export const ACTION_LOGOUT = 'ACTION_LOGOUT';
export const ACTION_FETCH_2FA_STATUS = 'ACTION_FETCH_2FA_STATUS';
// change locale user on server
export const UPDATE_USER_LOCALE = 'UPDATE_USER_LOCALE';
export const ACTION_GET_LIST_OF_SESSIONS = 'ACTION_GET_LIST_OF_SESSIONS';
export const ACTION_DELETE_ALL_SESSIONS_EXCEPT_CURRENT = 'ACTION_DELETE_ALL_SESSIONS_EXCEPT_CURRENT';

// mutations
export const MUTATION_SET_2FA_STATUS = 'MUTATION_SET_2FA_STATUS';
export const MUTATION_SET_IS_LOADING_STATUS = 'MUTATION_SET_IS_LOADING_STATUS';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';

// getters
export const GETTER_2FA_STATUS = 'GETTER_2FA_STATUS';
export const GETTER_IS_LOADING_STATUS = 'GETTER_IS_LOADING_STATUS';
