import { Action } from '@amarkets/atlas/esm/lib/action';
import { PhoneOrderMapper } from '../../../mappers/phone-order-mapper';
import { PhoneOrder } from '../../../entities/phone-order';
import {
  MUTATION_SET_CODE_SENT_AT,
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_IS_MOBILE,
  MUTATION_SET_PHONE_ORDER_ID,
} from '../types';

export class CreatePhoneOrder extends Action {
  _api;
  _handleError;

  constructor({ identifyApi, handleError }) {
    super();

    this._api = identifyApi;
    this._handleError = handleError;
  }

  async execute({ commit }, { number, userUid } = {}) {
    await CreatePhoneOrder._withLoading(async () => {
      try {
        const phoneOrderMapper = new PhoneOrderMapper((opts) => new PhoneOrder(opts));

        const { orderId, codeSentAt, isMobile } = await this._api.createPhoneOrder(
          {
            number,
            userUid,
          },
          phoneOrderMapper.mapPhoneOrder,
        );

        commit(MUTATION_SET_IS_MOBILE, { isMobile });

        if (isMobile) {
          commit(MUTATION_SET_PHONE_ORDER_ID, { orderId });
        }

        commit(MUTATION_SET_CODE_SENT_AT, { codeSentAt });
      } catch (error) {
        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute CreatePhoneOrder',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
