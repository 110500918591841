import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class UserProfileMapper extends Mapper {
  _userProfile;

  constructor(userProfile) {
    super();

    this._userProfile = userProfile;
  }

  mapUserProfile = (response) => UserProfileMapper._getResult(
    {
      ...response.data.attributes,
      id: response.data.id,
    }, this._userProfile,
  );
}
