import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class SingleItemMapper extends Mapper {
  _singleItemFactory;

  constructor(singleItemFactory) {
    super();
    this._singleItemFactory = singleItemFactory;
  }

  mapItem = (response) => SingleItemMapper._getResult({
    id: response.data.id,
    ...response.data.attributes,
  }, this._singleItemFactory);
}
