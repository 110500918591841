import { handleError } from '@/lib/helpers/handle-error';
import { ACTION_DISABLE_2FA } from '../types';
import { Disable2fa } from './disable-2fa';

export default function ({
  apiServices,
  errorHandler,
  httpErrorParser,
  uiNotifier,
  i18n,
}) {
  const { twoFactorApi } = apiServices;
  const _handleError = handleError(httpErrorParser, errorHandler);

  const disable2fa = new Disable2fa({
    twoFactorApi,
    uiNotifier,
    i18n,
    errorHandler: _handleError,
  });

  return {
    [ACTION_DISABLE_2FA]: disable2fa.execute.bind(disable2fa),
  };
}
