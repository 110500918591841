import { Action } from '@amarkets/atlas/esm/lib/action';
import { TwoFactorStatus } from '../../../entities/two-factor-status';
import { STATUS_2FA_AVAILABLE } from '../../../const';
import { MUTATION_SET_IS_LOADING } from '../types';

export class Disable2fa extends Action {
  _api;
  _errorHandler;
  _httpErrorParser;

  constructor({
    twoFactorApi,
    errorHandler,
    uiNotifier,
    i18n,
  }) {
    super();
    this._api = twoFactorApi;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute({ commit }, {
    password,
    onSuccess = () => {},
  }) {
    await Disable2fa._withLoading(async () => {
      try {
        await this._api.disableTotp({ password });

        const status = new TwoFactorStatus(STATUS_2FA_AVAILABLE);

        this._uiNotifier.success(this._i18n.global.t('security.dialogSuccessfulMessage.disable.title'));
        onSuccess(status);
      } catch (error) {
        this._errorHandler({
          error,
          commit,
          customErrMsg: 'while execute Disable2fa',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
