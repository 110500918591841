import {
  MUTATION_SET_DIGESTS_PREVIEW,
  MUTATION_SET_DAILY_EVENTS,
  MUTATION_SET_IDEAS,
  MUTATION_SET_DIGEST_ITEM,
  MUTATION_SET_EVENT_ITEM,
  MUTATION_SET_IDEA_ITEM,
  MUTATION_SET_TAGS,
  MUTATION_SET_STATE_TO_DEFAULT,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_DIGESTS_PREVIEW](state, digestsPreview) {
    state.digestsPreview = digestsPreview;
  },
  [MUTATION_SET_DAILY_EVENTS](state, dailyEvents) {
    state.dailyEvents = dailyEvents;
  },
  [MUTATION_SET_IDEAS](state, ideas) {
    state.ideas = ideas;
  },
  [MUTATION_SET_DIGEST_ITEM](state, digestItem) {
    if (!state.digestItems.some((item) => item.id === digestItem.id)) {
      state.digestItems = [...state.digestItems, digestItem];
    }
  },
  [MUTATION_SET_EVENT_ITEM](state, eventItem) {
    if (!state.eventItems.some((item) => item.id === eventItem.id)) {
      state.eventItems = [...state.eventItems, eventItem];
    }
  },
  [MUTATION_SET_IDEA_ITEM](state, ideaItem) {
    if (!state.ideaItems.some((item) => item.id === ideaItem.id)) {
      state.ideaItems = [...state.ideaItems, ideaItem];
    }
  },
  [MUTATION_SET_TAGS](state, tags) {
    state.tags = tags;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    Object.assign(state, defaultState);
  },
};
