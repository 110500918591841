import { merge } from '@amarkets/atlas/esm/lib/merge';
import {
  MUTATION_SET_USED_EMAIL,
  MUTATION_SET_STATE_TO_DEFAULT,
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_MESSAGE_ERROR,
  MUTATION_SET_TIMER_SECONDS,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_USED_EMAIL](state, { email }) {
    state.usedEmail = email;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    merge(state, defaultState);
  },
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_MESSAGE_ERROR](state, msg) {
    state.errorMessage = msg;
  },
  [MUTATION_SET_TIMER_SECONDS](state, seconds) {
    state.timerSeconds = seconds;
  },
};
