import { Action } from '@amarkets/atlas/esm/lib/action';
import { Trade } from '../entities/trade';
import { TradeMapper } from '../mappers/trade-mapper';
import {
  MUTATION_SET_TRADES,
  MUTATION_ADD_TRADES,
  MUTATION_SET_IS_TRADES_LOADING,
  MUTATION_SET_TRADES_PAGES_COUNT,
} from '../types';

export class FetchTrades extends Action {
  _api;
  _errorHandler;

  constructor({ agoraApi, errorHandler }) {
    super();

    this._api = agoraApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, {
    userUid,
    status,
    login,
    platform,
    pageNumber,
    pageSize,
    rewriteTrades,
  }) {
    await FetchTrades._withLoading(async () => {
      try {
        const tradeMapper = new TradeMapper((opts) => new Trade(opts));
        const { trades, meta } = await this._api.getTrades(
          { userUid, status, login, platform },
          { number: pageNumber, size: pageSize },
          (data) => tradeMapper.mapTrades(data),
        );

        commit(MUTATION_SET_TRADES_PAGES_COUNT, meta.totalPages);

        if (rewriteTrades) {
          commit(MUTATION_SET_TRADES, trades);
        } else {
          commit(MUTATION_ADD_TRADES, trades);
        }
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchTrades');
      }
    }, commit, MUTATION_SET_IS_TRADES_LOADING);
  }
}
