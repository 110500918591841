import { Action } from '@amarkets/atlas/esm/lib/action';
import { PartnerProfile } from '@/lib/entities/partner-profile';
import { UserMapper } from '../mappers/user-mapper';
import {
  MUTATION_SET_PARTNER_DATA,
  MUTATION_SET_PARTNER_DATA_LOADING,
} from '../types';

export class FetchPartnerProfile extends Action {
  _api;
  _errorHandler;

  constructor({ accountApi, errorHandler }) {
    super();
    this._api = accountApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchPartnerProfile._withLoading(async () => {
      try {
        const partnerMapper = new UserMapper((opts) => new PartnerProfile(opts));
        const partner = await this._api.getPartnerInfo((data) => partnerMapper.mapUser(data));

        commit(MUTATION_SET_PARTNER_DATA, partner);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchPartnerProfile');
      }
    }, commit, MUTATION_SET_PARTNER_DATA_LOADING);
  }
}
