import { handleError } from '@/lib/helpers/handle-error';
import {
  ACTION_SET_EMAIL,
  ACTION_CONFIRM_EMAIL_CODE,
} from '../types';
import { SetEmail } from './set-email';
import { ConfirmEmailCode } from './confirm-email-code';

export default function ({
  apiServices,
  errorHandler,
  httpErrorParser,
  uiNotifier,
  i18n,
}) {
  const { identifyApi } = apiServices;
  const _handleError = handleError(httpErrorParser, errorHandler);

  const setEmail = new SetEmail({
    identifyApi,
    handleError: _handleError,
    uiNotifier,
    i18n,
  });

  const confirmEmailCode = new ConfirmEmailCode({
    identifyApi,
    handleError: _handleError,
    uiNotifier,
    i18n,
  });

  return {
    [ACTION_SET_EMAIL]: setEmail.execute.bind(setEmail),
    [ACTION_CONFIRM_EMAIL_CODE]: confirmEmailCode.execute.bind(confirmEmailCode),
  };
}
