import { Action } from '@amarkets/atlas/esm/lib/action';
import { ACTION_FETCH_USER_PROFILE } from '../types';

export class ChangeAccountSettings extends Action {
  _api;
  _errorHandler;
  _uiNotifier;
  _i18n;

  constructor({ accountApi, errorHandler, uiNotifier, i18n }) {
    super();

    this._api = accountApi;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute({ dispatch }, {
    showBalance,
    showOnboarding,
    showPromo,
    latestTimeSuccessVerification,
    showGoldUpdatesBanner,
    onSuccess = () => {},
  }) {
    try {
      await this._api.changeAccountSettings({
        showBalance,
        showOnboarding,
        showPromo,
        latestTimeSuccessVerification,
        showGoldUpdatesBanner,
      });

      if (showBalance !== undefined) {
        const settingStatus = showBalance ? 'Enabled' : 'Disabled';
        this._uiNotifier.success(this._i18n.global.t(`notification.success.setting${settingStatus}`));
      }

      await dispatch(ACTION_FETCH_USER_PROFILE);
      onSuccess();
    } catch (e) {
      this._errorHandler.handle(e, 'while execute ChangeAccountSettings');
    }
  }
}
