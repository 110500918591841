import { Action } from '@amarkets/atlas/esm/lib/action';
import { TagsMapper } from '../mappers/tags-mapper';
import { TagItem } from '../entities/tag-item';
import { MUTATION_SET_TAGS } from '../types';

export class FetchTags extends Action {
  _api;
  _errorHandler;

  constructor({ premiumAnalyticsApi, errorHandler }) {
    super();
    this._api = premiumAnalyticsApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    try {
      const tagsMapper = new TagsMapper((opts) => new TagItem(opts));
      const tags = await this._api.getTags((data) => tagsMapper.mapTags(data));

      commit(MUTATION_SET_TAGS, tags);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchTags');
    }
  }
}
