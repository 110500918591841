import {
  ACTION_FETCH_PAYMENT_METHODS,
  ACTION_DELETE_PAYMENT_METHOD,
  ACTION_FETCH_PAYMENT_METHODS_TYPES,
} from '../types';
import { FetchPaymentMethods } from './fetch-payment-methods';
import { DeletePaymentMethod } from './delete-payment-method';
import { FetchPaymentMethodsTypes } from './fetch-payment-methods-types';

export default function ({
  apiServices,
  errorHandler,
}) {
  const { officeApi } = apiServices;
  const fetchPaymentMethods = new FetchPaymentMethods({ officeApi, errorHandler });
  const deletePaymentMethod = new DeletePaymentMethod({ officeApi, errorHandler });
  const fetchPaymentMethodsTypes = new FetchPaymentMethodsTypes({ officeApi, errorHandler });

  return {
    [ACTION_FETCH_PAYMENT_METHODS]: fetchPaymentMethods.execute.bind(fetchPaymentMethods),
    [ACTION_DELETE_PAYMENT_METHOD]: deletePaymentMethod.execute.bind(deletePaymentMethod),
    [ACTION_FETCH_PAYMENT_METHODS_TYPES]: fetchPaymentMethodsTypes.execute.bind(fetchPaymentMethodsTypes),
  };
}
