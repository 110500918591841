import { Action } from '@amarkets/atlas/esm/lib/action';
import { GeneralItem } from '../entities/general-item';
import { DigestsPreviewMapper } from '../mappers/digests-preview-mapper';
import { MUTATION_SET_DIGESTS_PREVIEW } from '../types';

export class FetchDigestsPreview extends Action {
  _api;
  _errorHandler;

  constructor({ premiumAnalyticsApi, errorHandler }) {
    super();
    this._api = premiumAnalyticsApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { pageNumber = 1, pageSize = 4, offset, locale } = {}) {
    try {
      const digestsMapper = new DigestsPreviewMapper((opts) => new GeneralItem({ ...opts, locale }));
      const digests = await this._api.getDigestsPreview({
        pageNumber,
        pageSize,
        offset,
      }, (data) => digestsMapper.mapDigests(data));

      commit(MUTATION_SET_DIGESTS_PREVIEW, digests);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchDigestsPreview');
    }
  }
}
