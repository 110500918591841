export class UserVerification {
  static VERIFICATION_STATE = {
    VERIFIED: 'verified',
    NOT_VERIFIED: 'not_verified',
    DISABLED: 'disabled',
    BLOCKED: 'blocked',
  };
  _verifiedStatus;
  _status;

  constructor(verifiedStatus) {
    this._verifiedStatus = verifiedStatus;
  }

  get isDisabled() {
    return this._status === UserVerification.VERIFICATION_STATE.DISABLED;
  }

  get isBlocked() {
    return this._status === UserVerification.VERIFICATION_STATE.BLOCKED;
  }

  get isVerified() {
    return this._status === UserVerification.VERIFICATION_STATE.VERIFIED;
  }

  get isNotVerified() {
    return this._status === UserVerification.VERIFICATION_STATE.NOT_VERIFIED;
  }
}
