import { Action } from '@amarkets/atlas/esm/lib/action';

export class FetchExchangeRateByCurrencies extends Action {
  _api;
  _errorHandler;

  constructor({ exchangeApi, errorHandler }) {
    super();
    this._api = exchangeApi;
    this._errorHandler = errorHandler;
  }

  async execute(_, { currencyFrom, currencyTo }) {
    let resp;

    try {
      resp = await this._api.getRateCurrentBySymbol(`${currencyFrom}${currencyTo}`);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchExchangeRateByCurrencies');
    }

    return resp;
  }
}
