import { Currency } from '@amarkets/atlas/esm/lib/currency';

export class Usd extends Currency {
  constructor({ value, precision, currency }) {
    super({
      value,
      symbol: currency,
      precision,
    });
  }
}
