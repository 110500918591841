import {
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_MESSAGE_ERROR,
  MUTATION_SET_STATE_TO_DEFAULT,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_MESSAGE_ERROR](state, msg) {
    state.validationErrorMessage = msg;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    Object.assign(state, defaultState);
  },
};
