import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class DigestsPreviewMapper extends Mapper {
  _digestsFactory;

  constructor(digestsFactory) {
    super();

    this._digestsFactory = digestsFactory;
  }

  mapDigests = (response) => ({
    data: response.data.data.map(
      ({ id, type, attributes }) => DigestsPreviewMapper._getResult({ id, type, ...attributes }, this._digestsFactory)),
    meta: response.data.meta.page,
  });
}
