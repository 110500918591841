import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_LOADING, ACTION_FETCH_PAYMENT_METHODS } from '../types';

export class DeletePaymentMethod extends Action {
  _api;
  _errorHandler;

  constructor({ officeApi, errorHandler }) {
    super();

    this._api = officeApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit, dispatch }, { type, uid }) {
    await DeletePaymentMethod._withLoading(async () => {
      try {
        await this._api.deletePaymentMethod({ type, uid });
        await dispatch(ACTION_FETCH_PAYMENT_METHODS);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute DeletePaymentMethod');
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
