import { PRIMARY_STATE } from '@/constants';

export default function availabilityOfPrimaryStateParam(route, next) {
  const isGuard = [
    PRIMARY_STATE.CHANGE,
    PRIMARY_STATE.CONFIRM,
  ].includes(route.params.primaryState);

  if (isGuard) next();
  else {
    next({
      name: route.name,
      params: { primaryState: PRIMARY_STATE.CONFIRM },
    });
  }
}
