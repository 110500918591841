import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class AllAccountsMapper extends Mapper {
  _accountsFactory;

  constructor(accountsFactory) {
    super();
    this._accountsFactory = accountsFactory;
  }

  mapAccounts = (response) => AllAccountsMapper._getResult(
    response.data, this._accountsFactory,
  );
}
