import { Action } from '@amarkets/atlas/esm/lib/action';
import { UserDocuments } from '../entities/main-documents';
import { UserDocumentsMapper } from '../mappers/documents-mapper';
import {
  MUTATION_SET_USER_DOCUMENTS,
  MUTATION_SET_IS_LOADING,
} from '../types';

export class FetchUserDocuments extends Action {
  _api;
  _errorHandler;

  constructor({ identifyApi, errorHandler }) {
    super();

    this._api = identifyApi;
    this._errorHandler = errorHandler;
  }

  async getUserDocuments(commit, { userId }) {
    try {
      const userDocumentsMapper = new UserDocumentsMapper((opts) => new UserDocuments(opts));
      const userDocuments = await this._api.getUserDocuments(
        { userId }, (data) => userDocumentsMapper.mapDocuments(data),
      );

      commit(MUTATION_SET_USER_DOCUMENTS, userDocuments);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchUserDocuments');
    }
  }
  async execute({ commit }, { userId, withoutLoading = false }) {
    if (withoutLoading) {
      await this.getUserDocuments(commit, { userId });
    } else {
      await FetchUserDocuments._withLoading(async () => {
        await this.getUserDocuments(commit, { userId });
      }, commit, MUTATION_SET_IS_LOADING);
    }
  }
}
