import { VuexHelper } from '@amarkets/atlas/esm/lib/vuex-helper';
import createActions from './promo/actions';
import state from './promo/state';
import mutations from './promo/mutations';
import getters from './promo/getters';

export default function (context) {
  return VuexHelper.createStoreModule({
    states: [state],
    actions: [createActions(context)],
    mutations: [mutations],
    getters: [getters],
  });
}
