import { PAYMENT_METHOD_NAMES } from '@/constants';

import { Eps } from './eps';
import { BankTransfer } from './bank-transfer';
import { Crypto } from './crypto';
import { Card } from './card';

export class PaymentMethod {
  static _PAYMENT_METHOD_NAMES = PAYMENT_METHOD_NAMES;

  constructor({ type, id, ...attributes }) {
    Object.assign(this, { type, id, ...PaymentMethod.create({ type, ...attributes }) });
  }

  static create({ type, ...attributes }) {
    const VALUES_BY_TYPE = {
      [PaymentMethod._PAYMENT_METHOD_NAMES.payment_method_card]: new Card(attributes),
      [PaymentMethod._PAYMENT_METHOD_NAMES.payment_method_crypto]: new Crypto(attributes),
      [PaymentMethod._PAYMENT_METHOD_NAMES.payment_method_acard]: {
        accountDetails: {
          schema: attributes.accountDetails?.schema,
          ...attributes.accountDetails?.data,
        },
      },
      [PaymentMethod._PAYMENT_METHOD_NAMES.payment_method_eps]: new Eps(attributes),
      [PaymentMethod._PAYMENT_METHOD_NAMES.payment_method_bank]: new BankTransfer(attributes),
    };

    return VALUES_BY_TYPE[type];
  }
}
