import {
  GETTER_USED_EMAIL,
  GETTER_IS_LOADING_STATUS,
  GETTER_MESSAGE_ERROR,
  GETTER_TIMER_SECONDS,
} from './types';

export default {
  [GETTER_USED_EMAIL]: ({ usedEmail }) => usedEmail,
  [GETTER_IS_LOADING_STATUS]: ({ isLoading }) => isLoading,
  [GETTER_MESSAGE_ERROR]: ({ errorMessage }) => errorMessage,
  [GETTER_TIMER_SECONDS]: ({ timerSeconds }) => timerSeconds,
};
