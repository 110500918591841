export class BankTransfer {
  static _MODEL = {
    name: '',
    number: '',
    code: '',
    city: '',
  };

  constructor({
    name,
    number,
    code,
    city,
  }) {
    Object.assign(this, {
      name: name ?? BankTransfer._MODEL.name,
      number: number ?? BankTransfer._MODEL.number,
      code: code ?? BankTransfer._MODEL.code,
      city: city ?? BankTransfer._MODEL.city,
    });
  }
}
