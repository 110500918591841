import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_LOADING_PASSWORD } from '../types';

export class SendRecoveryEmail extends Action {
  _api;
  _errorHandler;

  constructor({ authApi, errorHandler }) {
    super();

    this._api = authApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { email, clientId, redirectUri, state } = {}) {
    await SendRecoveryEmail._withLoading(async () => {
      try {
        await this._api.resetPassword({
          email,
          clientId,
          redirectUri,
          state,
        });
      } catch (e) {
        this._errorHandler.handle(e, 'while execute SendRecoveryEmail');
      }
    }, commit, MUTATION_SET_IS_LOADING_PASSWORD);
  }
}
