import { SentryService } from '@/plugins/sentry/sentry';

export const createSentry = ({ config }) => ({ app, router }) => new SentryService({
  app,
  dsn: config.SENTRY.DSN,
  release: config.APP.APP_RELEASE,
  sampleRate: config.SENTRY.ENV === 'production' ? 1.0 : 0.5,
  attachStacktrace: true,
  logErrors: true,
  environment: config.SENTRY.ENV,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  router,
});
