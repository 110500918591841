import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_GETID_CONFIG,
  MUTATION_SET_IS_LOADING,
} from '../types';
import { GetidConfigMapper } from '../mappers/getid-config-mapper';
import { GetidConfig } from '../entities/getid-config';

export class FetchGetidConfig extends Action {
  _api;
  _errorHandler;

  constructor({ officeApi, errorHandler }) {
    super();

    this._api = officeApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { currentLocale }) {
    await FetchGetidConfig._withLoading(async () => {
      try {
        const getidConfigMapper = new GetidConfigMapper((opts) => new GetidConfig(opts));
        const getidConfig = await this._api.getGetidConfig(
          { currentLocale }, (data) => getidConfigMapper.mapGetidConfig(data),
        );

        commit(MUTATION_SET_GETID_CONFIG, getidConfig);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchGetidConfig');
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
