import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class UserMapper extends Mapper {
  _user;

  constructor(userData) {
    super();
    this._user = userData;
  }

  mapUser = (response) => UserMapper._getResult(
    response.data.attributes, this._user,
  );
}
