import { Action } from '@amarkets/atlas/esm/lib/action';
import { isFunction } from '@amarkets/atlas/esm/lib/is-function';

export class UnArchiveTradingAccount extends Action {
  _api;
  _errorHandler;
  _uiNotifier;
  _i18n;

  constructor({ officeApi, errorHandler, uiNotifier, i18n }) {
    super();

    this._api = officeApi;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute(_, { tradingAccountUuid, tradingAccountName, onSuccess }) {
    try {
      await this._api.unArchiveTradingAccount(tradingAccountUuid);
      this._uiNotifier.success(this._i18n.global.t(
        'notification.success.tradingAccountUnArchived',
        { tradingAccountName },
      ));

      if (isFunction(onSuccess)) onSuccess();
    } catch (e) {
      this._errorHandler.handle(e, 'while execute UnArchiveTradingAccount');
    }
  }
}
