// module name
export const STORE_WIDGET_PROMO = 'STORE_WIDGET_PROMO';

// actions
export const ACTION_FETCH_CASHBACK = 'ACTION_FETCH_CASHBACK';

// mutations
export const MUTATION_SET_IS_CASHBACK_LOADING = 'MUTATION_SET_IS_CASHBACK_LOADING';
export const MUTATION_SET_CASHBACK = 'MUTATION_SET_CASHBACK';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';

// getters
export const GETTER_IS_CASHBACK_LOADING = 'GETTER_IS_CASHBACK_LOADING';
export const GETTER_CASHBACK = 'GETTER_CASHBACK';
