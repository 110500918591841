export class Eps {
  static _MODEL = {
    number: '',
    paymentProvider: '',
  };

  constructor({ number, paymentProvider }) {
    Object.assign(this, {
      paymentProvider: paymentProvider ?? Eps._MODEL.paymentProvider,
      number: number ?? Eps._MODEL.number,
    });
  }
}
