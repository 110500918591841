import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class TradingAccountsMapper extends Mapper {
  _tradingAccount;

  constructor(tradingAccount) {
    super();
    this._tradingAccount = tradingAccount;
  }

  mapTradingAccount = (response) => response.data.map(
    (tradingAccount) => TradingAccountsMapper._getResult(tradingAccount, this._tradingAccount),
  );
}
