// module name
export const STORE_MODULE_PAYMENT_METHODS = 'STORE_MODULE_PAYMENT_METHODS';

// actions
export const ACTION_FETCH_PAYMENT_METHODS = 'ACTION_FETCH_PAYMENT_METHODS';
export const ACTION_DELETE_PAYMENT_METHOD = 'ACTION_DELETE_PAYMENT_METHOD';
export const ACTION_FETCH_PAYMENT_METHODS_TYPES = 'ACTION_FETCH_PAYMENT_METHODS_TYPES';

// mutations
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';
export const MUTATION_SET_PAYMENT_METHODS = 'MUTATION_SET_PAYMENT_METHODS';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';

export const MUTATION_SET_PAYMENT_METHODS_TYPES = 'MUTATION_SET_PAYMENT_METHODS_TYPES';
export const MUTATION_SET_IS_LOADING_TYPES = 'MUTATION_SET_IS_LOADING_TYPES';

// getters
export const GETTER_IS_LOADING_STATUS = 'GETTER_IS_LOADING_STATUS';
export const GETTER_PAYMENT_METHODS = 'GETTER_PAYMENT_METHODS';
export const GETTER_PAYMENT_METHOD_BY_ID = 'GETTER_PAYMENT_METHOD_BY_ID';
export const GETTER_PAYMENT_METHOD_BY_TYPE = 'GETTER_PAYMENT_METHOD_BY_TYPE';

export const GETTER_PAYMENT_METHODS_TYPES = 'GETTER_PAYMENT_METHODS_TYPES';
export const GETTER_IS_VERIFICATION_REQUIRED = 'GETTER_IS_VERIFICATION_REQUIRED';
export const GETTER_IS_LOADING_TYPES = 'GETTER_IS_LOADING_TYPES';
