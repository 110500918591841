import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_LOADING_PASSWORD } from '../types';

export class ChangePassword extends Action {
  _api;
  _handleError;
  _uiNotifier;
  _i18n;

  constructor({
    identifyApi,
    handleError,
    uiNotifier,
    i18n,
  }) {
    super();

    this._api = identifyApi;
    this._handleError = handleError;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute({ commit }, { currentPassword, password, passwordConfirmation, onSuccess = () => ({}) } = {}) {
    await ChangePassword._withLoading(async () => {
      try {
        await this._api.changePassword({
          currentPassword,
          password,
          passwordConfirmation,
        });

        onSuccess();

        this._uiNotifier.success(this._i18n.global.t('notification.success.passwordChanged'));
      } catch (error) {
        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute ChangePassword',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING_PASSWORD);
  }
}
