import { AppModuleNext } from '@amarkets/atlas/esm/lib/app-module-next';
import { STORE_MODULE_DISABLE_2FA } from './const';

const rootComponent = () => import(
  './components/module-root/module-root.vue'
);

const storeAsyncFactory = () => import(
  './store'
);

const props = {
  status2fa: {
    type: Object,
    require: true,
  },
};

const propsProviderFactory = (moduleRoot) => ({
  getStatus: () => moduleRoot.status2fa,
});

const eventsFactory = (moduleRoot) => ({
  disable2fa($event) {
    moduleRoot.$emit('disable-2fa', $event);
  },
});

export default {
  module: new AppModuleNext(rootComponent, {
    storeModuleName: STORE_MODULE_DISABLE_2FA,
    storeAsyncFactory,
    propsProviderFactory,
    props,
    eventsFactory,
  }),
};
