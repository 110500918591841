import { Usd } from '@/lib/values/usd';

export class Cashback {
  constructor({
    daysLeft,
    grade,
    gradeCost,
    lastCharge,
    maxGrade,
    monthVolume,
    nextGradeDaysCount,
    nextGradeLotsCount,
    totalCharge,
  }) {
    const cost = new Usd({
      value: gradeCost.amount,
      currency: gradeCost.currency,
      precision: 2,
    }).currency.format();

    Object.assign(this, {
      gradeCost: cost,
      daysLeft,
      grade,
      lastCharge,
      maxGrade,
      monthVolume,
      nextGradeDaysCount,
      nextGradeLotsCount,
      totalCharge,
    });
  }
}
