import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class PhoneOrderMapper extends Mapper {
  _phoneOrderMapperFactory;

  constructor(phoneOrderMapperFactory) {
    super();

    this._phoneOrderMapperFactory = phoneOrderMapperFactory;
  }

  mapPhoneOrder = (response) => PhoneOrderMapper._getResult({
    orderId: response.data.id,
    codeSentAt: response.data.attributes.codeSentAt || 0,
    isMobile: response.data.attributes.isMobile,
  }, this._phoneOrderMapperFactory);
}
