export const STORE_MODULE_DISABLE_2FA = 'STORE_MODULE_DISABLE_2FA';

export const TOGGLE_2FA_ENABLE_TYPE = 'enable';
export const TOGGLE_2FA_DISABLE_TYPE = 'disable';

export const STATUS_2FA_ENABLED = 'enabled';
export const STATUS_2FA_FORBIDDEN = 'forbidden';
export const STATUS_2FA_AVAILABLE = 'available';

export const MODAL_TYPES = {
  DISABLE: 'disable',
  SUCCESSFUL: 'successful',
};

export const getStatus = (isAvailable = true) => ({
  isAvailable,
});
