import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class StatusMapper extends Mapper {
  _statusFactory;

  constructor(statusFactory) {
    super();

    this._statusFactory = statusFactory;
  }

  mapStatus = (response) => StatusMapper._getResult(response.data.attributes.totp.status, this._statusFactory);
}
