import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class EcnPromoMapper extends Mapper {
  _ecnPromo;

  constructor(ecnPromo) {
    super();
    this._ecnPromo = ecnPromo;
  }

  mapEcnPromo = (response) => EcnPromoMapper._getResult(
    response, this._ecnPromo,
  );
}
