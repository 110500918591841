import { handleError } from '@/lib/helpers/handle-error';
import {
  ACTION_SEND_RECOVERY_EMAIL,
  ACTION_SET_PASSWORD,
  ACTION_CHANGE_PASSWORD,
} from '../types';
import { SendRecoveryEmail } from './send-recovery-email';
import { SetPassword } from './set-password';
import { ChangePassword } from './change-password';

export default function ({
  apiServices,
  errorHandler,
  httpErrorParser,
  uiNotifier,
  i18n,
}) {
  const { identifyApi } = apiServices;
  const _handleError = handleError(httpErrorParser, errorHandler);

  const sendRecoveryEmail = new SendRecoveryEmail({
    identifyApi,
    errorHandler,
  });

  const setPassword = new SetPassword({
    identifyApi,
    errorHandler,
    httpErrorParser,
    uiNotifier,
    i18n,
  });

  const changePassword = new ChangePassword({
    identifyApi,
    handleError: _handleError,
    uiNotifier,
    i18n,
  });

  return {
    [ACTION_SEND_RECOVERY_EMAIL]: sendRecoveryEmail.execute.bind(sendRecoveryEmail),
    [ACTION_SET_PASSWORD]: setPassword.execute.bind(setPassword),
    [ACTION_CHANGE_PASSWORD]: changePassword.execute.bind(changePassword),
  };
}
