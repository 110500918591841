import _refiner from 'refiner-js';
import { createRefinerUserData } from './create-refiner-user-data';
import { getSurveyAliasByLocale } from './get-survey-alias-by-locale';

export const refiner = {
  setProject(projectId) {
    _refiner('setProject', projectId);
  },
  identifyUser({ userUid, userProfile }) {
    _refiner('identifyUser', createRefinerUserData({ userUid, userProfile }));
  },
  showRiskProfileSurvey(locale) {
    _refiner('showForm', getSurveyAliasByLocale(locale));
  },
};
