export class OpenedAccount {
  constructor({
    uuid,
    login,
    password,
  }) {
    Object.assign(this, {
      accountUuid: uuid,
      accountLogin: login,
      accountPassword: password,
    });
  }
}
