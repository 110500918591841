import { Mapper } from '@amarkets/atlas/esm/lib/mapper';
import { DOCUMENT_STATUSES } from '@/constants';

export class UserDocumentsMapper extends Mapper {
  _documents;

  constructor(userDocuments) {
    super();
    this._documents = userDocuments;
  }

  mapDocuments = (response) => {
    const documents = response.data;

    if (documents.length) {
      return UserDocumentsMapper._getResult(
        documents[0].attributes, this._documents,
      );
    }

    return {
      status: DOCUMENT_STATUSES.NONE,
    };
  };
}
