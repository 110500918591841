import { LOCALES } from '@/constants';

export const RISK_PROFILE_ALIASES_BY_LOCALE = {
  [LOCALES.EN]: 'userRiskProfile_en',
  [LOCALES.FA]: 'userRiskProfile_fa',
  [LOCALES.MS]: 'userRiskProfile_ms',
  [LOCALES.RU]: 'userRiskProfile_ru',
  [LOCALES.UZ]: 'userRickProfile_uz',
  'default': 'userRiskProfile_en',
};

export const getSurveyAliasByLocale = (locale) => RISK_PROFILE_ALIASES_BY_LOCALE[locale]
  || RISK_PROFILE_ALIASES_BY_LOCALE.default;
