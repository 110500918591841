/* eslint-disable no-console */
import { Centrifuge } from 'centrifuge';

export const createCentrifuge = ({ config, jwtService }) => {
  console.log({ config, jwtService });

  if (!config.WEBSOCKETS) {
    return false;
  }

  const centrifuge = new Centrifuge(config.WEBSOCKETS, {
    data: { token: jwtService.accessToken },
    getData() {
      jwtService.updateToken();

      return new Promise((resolve, reject) => {
        const updatedAccessToken = jwtService.accessToken;

        if (!updatedAccessToken) {
          reject(Centrifuge.UnauthorizedError);
        }

        resolve({ token: updatedAccessToken });
      });
    },
  });

  centrifuge.on('connecting', (ctx) => {
    console.log(`Connecting: ${ctx.code}, ${ctx.reason}`);
  });

  centrifuge.on('connected', (ctx) => {
    console.log(`Connected over ${ctx.transport}`);
  });

  centrifuge.on('disconnected', (ctx) => {
    console.log(`Disconnected: ${ctx.code}, ${ctx.reason}`);
  });

  centrifuge.connect();

  if (!centrifuge) {
    throw new Error('Centrifuge instance is not ready');
  }

  const subscribeToChannel = (nameChannel, callback) => {
    const subscription = centrifuge.getSubscription(nameChannel);
    if (subscription) {
      console.log('centrifuge already subscribed', nameChannel);

      return subscription;
    }

    console.log('centrifuge subscribe', nameChannel);
    const subscribe = centrifuge.newSubscription(nameChannel);
    subscribe.subscribe();
    subscribe.on('publication', callback);

    return subscribe;
  };

  const unsubscribeFromChannel = (subscription) => {
    if (subscription) subscription.unsubscribe();
  };

  return {
    centrifuge,
    connect: () => centrifuge.connect(),
    disconnect: () => centrifuge.disconnect(),
    subscribe: subscribeToChannel,
    unsubscribe: unsubscribeFromChannel,
  };
};
