import { STORE_MODULE_GLOBAL, GETTER_USER_PROFILE } from '@/plugins/store/modules/global/types';
import { ROUTES } from '@/constants';

export default function isBlockingUser(router) {
  return (to, from, next) => {
    const { officeTotalLocked } = router.getters[`${STORE_MODULE_GLOBAL}/${GETTER_USER_PROFILE}`];

    if (officeTotalLocked && to.name !== ROUTES.USER_BLOCKED.NAME) {
      next({ name: ROUTES.USER_BLOCKED.NAME });
      return;
    }

    if (!officeTotalLocked && to.name === ROUTES.USER_BLOCKED.NAME) {
      next(from);
      return;
    }

    next();
  };
}
