import { reactive } from 'vue';
import { APP_INIT_STATES, HTTP_ERRORS } from '@/constants';

const state = reactive({
  initState: APP_INIT_STATES.LOADING,
});
export const setToLoaded = () => { state.initState = APP_INIT_STATES.LOADED; };
export const setToError = (error) => {
  if (error?.response?.status !== HTTP_ERRORS.UNAUTHORIZED) {
    state.initState = APP_INIT_STATES.ERROR;
  }
};
export const setToLoading = () => { state.initState = APP_INIT_STATES.LOADING; };

export default state;
