import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class TagsMapper extends Mapper {
  _tagItem;

  constructor(tagItem) {
    super();
    this._tagItem = tagItem;
  }

  mapTags = (response) => response.data.data.map(
    ({ id, attributes }) => TagsMapper._getResult({ id, ...attributes }, this._tagItem),
  );
}
