import { PAYMENT_METHOD_TYPES, PAYMENT_METHOD_TYPES_TO_NAMES } from '@/constants';

export class PaymentMethodType {
  static PAYMENT_METHOD_TYPES_LABELS = {
    [PAYMENT_METHOD_TYPES.CARD]: 'paymentMethod.bankCard',
    [PAYMENT_METHOD_TYPES.CRYPTO]: 'paymentMethod.cryptoWallet',
    [PAYMENT_METHOD_TYPES.EPS]: 'paymentMethod.onlineWallet',
    [PAYMENT_METHOD_TYPES.BANK]: 'paymentMethod.bankTransfer',
    [PAYMENT_METHOD_TYPES.ACARD]: 'paymentMethod.ACARD.title',
    [PAYMENT_METHOD_TYPES.ACARD_GLOBAL]: 'paymentMethod.ACARD.title',
  };

  constructor({
    id,
    verificationRequired,
  }) {
    Object.assign(this, {
      id,
      value: PAYMENT_METHOD_TYPES_TO_NAMES[id],
      text: PaymentMethodType._getLabel(id),
      verificationRequired,
    });
  }

  static _getLabel(type) {
    return PaymentMethodType.PAYMENT_METHOD_TYPES_LABELS[type];
  }
}
