import { Action } from '@amarkets/atlas/esm/lib/action';
import { Cashback } from '../entities/cashback';
import { CashbackMapper } from '../mappers/cashback-mapper';
import {
  MUTATION_SET_CASHBACK,
  MUTATION_SET_IS_CASHBACK_LOADING,
} from '../types';

export class FetchAccountCashback extends Action {
  _api;
  _errorHandler;

  constructor({ accountApi, errorHandler }) {
    super();

    this._api = accountApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { uid }) {
    await FetchAccountCashback._withLoading(async () => {
      try {
        const cashbackMapper = new CashbackMapper((opts) => new Cashback(opts));
        const cashback = await this._api.getAccountCashback(
          { uid }, (data) => cashbackMapper.mapCashback(data),
        );

        commit(MUTATION_SET_CASHBACK, cashback);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchAccountCashback');
      }
    }, commit, MUTATION_SET_IS_CASHBACK_LOADING);
  }
}
