import {
  ACTION_FETCH_DIGESTS_PREVIEW,
  ACTION_FETCH_DAILY_EVENTS,
  ACTION_FETCH_IDEAS,
  ACTION_FETCH_DIGEST_ITEM,
  ACTION_FETCH_EVENT_ITEM,
  ACTION_FETCH_IDEA_ITEM,
  ACTION_FETCH_TAGS,
} from '../types';
import { FetchDigestsPreview } from './fetch-digests';
import { FetchDailyEvents } from './fetch-events';
import { FetchIdeas } from './fetch-ideas';
import { FetchDigestItem } from './fetch-digest';
import { FetchEventItem } from './fetch-event';
import { FetchIdeaItem } from './fetch-idea';
import { FetchTags } from './fetch-tags';

export default function ({
  apiServices,
  errorHandler,
}) {
  const { premiumAnalyticsApi } = apiServices;
  const fetchDigestsPreview = new FetchDigestsPreview({ premiumAnalyticsApi, errorHandler });
  const fetchDailyEvents = new FetchDailyEvents({ premiumAnalyticsApi, errorHandler });
  const fetchIdeas = new FetchIdeas({ premiumAnalyticsApi, errorHandler });
  const fetchDigestItem = new FetchDigestItem({ premiumAnalyticsApi, errorHandler });
  const fetchEventItem = new FetchEventItem({ premiumAnalyticsApi, errorHandler });
  const fetchIdeaItem = new FetchIdeaItem({ premiumAnalyticsApi, errorHandler });
  const fetchTags = new FetchTags({ premiumAnalyticsApi, errorHandler });
  return {
    [ACTION_FETCH_DIGESTS_PREVIEW]: fetchDigestsPreview.execute.bind(fetchDigestsPreview),
    [ACTION_FETCH_DAILY_EVENTS]: fetchDailyEvents.execute.bind(fetchDailyEvents),
    [ACTION_FETCH_IDEAS]: fetchIdeas.execute.bind(fetchIdeas),
    [ACTION_FETCH_DIGEST_ITEM]: fetchDigestItem.execute.bind(fetchDigestItem),
    [ACTION_FETCH_EVENT_ITEM]: fetchEventItem.execute.bind(fetchEventItem),
    [ACTION_FETCH_IDEA_ITEM]: fetchIdeaItem.execute.bind(fetchIdeaItem),
    [ACTION_FETCH_TAGS]: fetchTags.execute.bind(fetchTags),
  };
}
