import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class TradeMapper extends Mapper {
  _tradeFactory;

  constructor(tradeFactory) {
    super();
    this._tradeFactory = tradeFactory;
  }

  mapTrades = (response) => ({
    trades: response.data.map(
      (trade) => TradeMapper._getResult(trade.attributes, this._tradeFactory),
    ),
    meta: response.meta,
  });
}
