<template>
  <div class="layout-init-loading d-flex flex-column align-center">
    <app-spinner-dot
      class="layout-init-loading__spinner"
      size="m"
    />
    <svg
      class="mt-auto mb-4 mb-sm-6 mb-md-8 mb-lg-12"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.9085 9.77926L7.39079 79.4704C7.17236 80.014 7.60939 80.6663 8.26503 80.6663H14.221C15.3684 80.6663 16.4066 79.9596 16.7892 78.8725L38.2633 19.9992C38.5911 19.1838 39.7385 19.1838 40.0117 19.9992L50.7762 49.463H33.1269C31.9795 49.463 30.9413 50.1697 30.5589 51.2569L28.3186 57.3997C28.1 57.9434 28.5372 58.5957 29.1928 58.5957H54.1639L55.53 62.238H28.4824C27.335 62.238 26.2967 62.9447 25.9143 64.0318L23.674 70.1746C23.4554 70.7182 23.8926 71.3707 24.5483 71.3707H58.9179L61.6333 78.8689C62.0159 79.9561 63.0358 80.6628 64.1833 80.6628L75.5685 80.6663C76.2243 80.6663 76.6613 80.0684 76.4427 79.4704L73.5071 71.4249H79.7363C80.3919 71.4249 80.8289 70.8269 80.6105 70.229L78.3702 64.0862C77.9876 62.999 76.9494 62.2923 75.802 62.2923H70.174L68.808 58.6501H75.037C75.6928 58.6501 76.1298 58.0522 75.9114 57.454L73.6709 51.3112C73.2885 50.2241 72.2503 49.5174 71.1028 49.5174H65.4747L50.9401 9.77926C50.3936 8.31151 48.973 7.33301 47.3883 7.33301H36.46C34.8756 7.33301 33.4549 8.31151 32.9085 9.77926Z"
        fill="url(#paint0_linear_27555_66009)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_27555_66009"
          x1="43.9924"
          y1="10.4173"
          x2="43.9924"
          y2="84.5156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F39200" />
          <stop
            offset="1"
            stop-color="#F07E00"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import { AppSpinnerDot } from '@amarkets/ui-kit-next';

export default {
  name: 'layout-init-loading',
  components: {
    AppSpinnerDot,
  },
};
</script>

<style lang="scss" scoped>
  @import 'layout-init-loading';
</style>
