import { Action } from '@amarkets/atlas/esm/lib/action';

export class ArchiveTradingAccount extends Action {
  _api;
  _errorHandler;
  _uiNotifier;
  _i18n;

  constructor({ officeApi, errorHandler, uiNotifier, i18n }) {
    super();

    this._api = officeApi;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute(_, { tradingAccountUuid, tradingAccountName }) {
    try {
      await this._api.archiveTradingAccount(tradingAccountUuid);
      this._uiNotifier.success(this._i18n.global.t(
        'notification.success.tradingAccountArchived',
        { tradingAccountName },
      ));
    } catch (e) {
      this._errorHandler.handle(e, 'while execute ArchiveTradingAccount');
    }
  }
}
