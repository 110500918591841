import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class PaymentMethodsTypesMapper extends Mapper {
  _responseFactory;

  constructor(paymentMethodsTypesFactory) {
    super();
    this._responseFactory = paymentMethodsTypesFactory;
  }

  mapPaymentMethodsTypes = (response) => response.map((type) => (
    PaymentMethodsTypesMapper._getResult(type, this._responseFactory)),
  );
}
