// actions
export const ACTION_SET_EMAIL = 'ACTION_SET_EMAIL';
export const ACTION_CONFIRM_EMAIL_CODE = 'ACTION_CONFIRM_EMAIL_CODE';

// mutations
export const MUTATION_SET_USED_EMAIL = 'MUTATION_SET_USED_EMAIL';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_MESSAGE_ERROR = 'MUTATION_SET_MESSAGE_ERROR';
export const MUTATION_SET_TIMER_SECONDS = 'MUTATION_SET_TIMER_SECONDS';

// getters
export const GETTER_USED_EMAIL = 'GETTER_USED_EMAIL';
export const GETTER_IS_LOADING_STATUS = 'GETTER_IS_LOADING_STATUS';
export const GETTER_MESSAGE_ERROR = 'GETTER_MESSAGE_ERROR';
export const GETTER_TIMER_SECONDS = 'GETTER_TIMER_SECONDS';
