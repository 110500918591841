import { UserVerification } from './user-verification';

export class PhoneVerificationState extends UserVerification {
  constructor({ phoneNumberVerified, emailVerified }) {
    super(phoneNumberVerified);

    this._emailVerified = emailVerified;
    this._status = this.phoneVerificationState;
  }

  get phoneVerificationState() {
    if (this._verifiedStatus) return UserVerification.VERIFICATION_STATE.VERIFIED;
    if (!this._emailVerified) return UserVerification.VERIFICATION_STATE.DISABLED;
    // TODO: add BLOCKED state when backend will return this info
    // if (this.userInfo.<userConfirmationBlocked>) return VERIFICATION_STATE.BLOCKED;
    return UserVerification.VERIFICATION_STATE.NOT_VERIFIED;
  }
}
