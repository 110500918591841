import { Action } from '@amarkets/atlas/esm/lib/action';

import { MUTATION_SET_ACCOUNT_GROUPS, MUTATION_SET_IS_LOADING } from '../types';
import { AccountGroupsMapper } from '../mappers/account-groups-mapper';
import { AccountGroup } from '../entities/account-group';

export class FetchAccountGroups extends Action {
  _api;
  _handleError;

  constructor({ officeApi, handleError }) {
    super();
    this._api = officeApi;
    this._handleError = handleError;
  }

  async execute({ commit }) {
    await FetchAccountGroups._withLoading(async () => {
      try {
        const accountGroupsMapper = new AccountGroupsMapper((opts) => new AccountGroup(opts));
        const accountGroups = await this._api.getAccountGroups((data) => accountGroupsMapper.mapAccountGroups(data));

        commit(MUTATION_SET_ACCOUNT_GROUPS, accountGroups);
      } catch (error) {
        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute FetchAccountGroups',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
