import { Mapper } from '@amarkets/atlas/esm/lib/mapper';
import { DEPOSIT_ACCOUNT_TYPES } from '@/constants/index';

export class AccountsMapper extends Mapper {
  _accountsFactory;

  constructor(accountsFactory) {
    super();

    this._accountsFactory = accountsFactory;
  }

  mapAccounts = (response) => {
    const { realAccounts, wallets } = response.data.attributes;
    const accountsWithType = realAccounts.map((item) => ({
      ...item,
      type: DEPOSIT_ACCOUNT_TYPES.ACCOUNT,
    }));
    const walletsWithType = wallets.map((item) => ({
      ...item,
      type: DEPOSIT_ACCOUNT_TYPES.WALLET,
    }));

    return [...accountsWithType, ...walletsWithType].map((item) => (
      AccountsMapper._getResult(item, this._accountsFactory)),
    );
  };
}
