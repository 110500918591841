import { Currency } from '@amarkets/atlas/esm/lib/currency';

export class PaymentMethodItem {
  constructor(payload) {
    const {
      id,
      name,
      minAmount,
      maxAmount,
      group,
      iconPath,
      publicFor,
      commission,
      processingCurrency,
      processingTime,
      archived,
      paymentClosed,
      recommendedForCountry,
      blockchains,
    } = payload;
    const currencies = [];

    if (blockchains && blockchains.length) {
      currencies.push({
        currency: blockchains[0].currency,
        color: 'warning',
      });
    }

    if (processingCurrency) {
      currencies.push({
        currency: processingCurrency,
        color: 'link',
      });
    }

    Object.assign(this, {
      id,
      name,
      minAmount,
      maxAmount,
      minAmountWithCurrency: new Currency({
        value: minAmount,
        symbol: processingCurrency,
        precision: 2,
      }).currency.format(),
      maxAmountWithCurrency: maxAmount ? new Currency({
        value: maxAmount,
        symbol: processingCurrency,
        precision: 2,
      }).currency.format() : '',
      currencies,
      processingCurrency,
      group,
      iconPath,
      publicFor,
      processingTime,
      fee: commission,
      archived,
      paymentClosed,
      recommendedForCountry,
    });
  }
}
