import { Action } from '@amarkets/atlas/esm/lib/action';

export class DeleteSessions extends Action {
  _api;
  _errorHandler;
  _uiNotifier;
  _i18n;

  constructor({ authApi, errorHandler, uiNotifier, i18n }) {
    super();

    this._api = authApi;
    this._errorHandler = errorHandler;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
  }

  async execute(_, { uuid, onSuccess = () => {} }) {
    try {
      await this._api.deleteAllSessionsExceptCurrent({ uuid });
      this._uiNotifier.success(this._i18n.global.t('profile.security.pageSessions.terminatedOtherSessions'));
      onSuccess();
    } catch (e) {
      this._errorHandler.handle(e, 'while execute DeleteSessions');
    }
  }
}
