export default {
  ROOT: {
    PATH: '/',
    NAME: 'home',
  },
  MAIN: {
    PATH: '/main',
    NAME: 'main',
  },
  USER_BLOCKED: {
    PATH: '/blocked',
    NAME: 'user-blocked',
  },
  SECURITY: {
    PATH: '/security',
    NAME: 'security',
  },
  TWO_FACTOR_ENABLE: {
    PATH: '/two-factor-enable',
    NAME: 'two-factor-enable',
  },
  VERIFICATION: {
    PATH: '/verification',
    NAME: 'verification',
  },
  PROFILE: {
    PATH: '/profile',
    NAME: 'profile',
  },
  FINANCE: {
    PATH: '/finance',
    NAME: 'finance',
  },
  DEBUG: {
    PATH: '/webview-debug',
    NAME: 'debug',
  },
  MT_WEB: {
    PATH: '/mt-web/:type',
    NAME: 'mt-web',
  },
  GET_ID_COUSIN: {
    PATH: '/page-get-id-cousin',
    NAME: 'page-get-id-cousin',
  },
  FRIENDS_AND_AWARDS: {
    PATH: '/friends-and-awards',
    NAME: 'friends-and-awards',
  },
};
