import { merge } from '@amarkets/atlas/esm/lib/merge';
import {
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_MESSAGE_ERROR,
  MUTATION_SET_USED_PHONE,
  MUTATION_SET_PHONE_ORDER_ID,
  MUTATION_SET_ATTEMPTS,
  MUTATION_SET_CODE_SENT_AT,
  MUTATION_SET_IS_MOBILE,
  MUTATION_SET_STATE_TO_DEFAULT,
  MUTATION_SET_VERIFICATION_METHOD,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_MESSAGE_ERROR](state, msg) {
    state.errorMessage = msg;
  },
  [MUTATION_SET_USED_PHONE](state, { phone }) {
    state.usedPhone = phone;
  },
  [MUTATION_SET_PHONE_ORDER_ID](state, { orderId }) {
    state.phoneOrderId = orderId;
  },
  [MUTATION_SET_ATTEMPTS](state, { attempts }) {
    state.attempts = attempts;
  },
  [MUTATION_SET_CODE_SENT_AT](state, { codeSentAt }) {
    state.codeSentAt = codeSentAt;
  },
  [MUTATION_SET_IS_MOBILE](state, { isMobile }) {
    state.isMobile = isMobile;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    merge(state, defaultState);
  },
  [MUTATION_SET_VERIFICATION_METHOD](state, verificationMethod) {
    state.verificationMethod = verificationMethod;
  },
};
