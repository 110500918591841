import { ROUTES } from '../const';

export function routesFactory(rootComponent) {
  return {
    path: ROUTES.SECURITY.PATH,
    name: '',
    component: rootComponent,
    children: [
      {
        path: '',
        name: ROUTES.SECURITY.NAME,
        component: () => import(
          /* webpackChunkName: "page-security" */
          '../views/page-security.vue'
        ),
      },
      // {
      //   path: ROUTES.ADD_SECOND_FACTOR.PATH,
      //   name: ROUTES.ADD_SECOND_FACTOR.NAME,
      //   component: () => import(
      //     /* webpackChunkName: "page-enable-second-factor" */
      //     '../views/page-enable-second-factor.vue'
      //   ),
      // },
    ],
  };
}
