import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class PaymentMethodMapper extends Mapper {
  _responseFactory;

  constructor(paymentMethodFactory) {
    super();
    this._responseFactory = paymentMethodFactory;
  }

  mapPaymentMethods = (response) => response.map(
    (item) => PaymentMethodMapper._getResult({
      type: item.data.type,
      id: item.data.id,
      ...item.data.attributes,
    }, this._responseFactory),
  );
}
