import { createApp, h } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import { ErrorHandler } from '@amarkets/atlas/esm/lib/error-handler';
import { App } from '@/app';
import { I18N_ERROR_KEYS } from '@/constants';
import { createUiServiceProvider } from './factories/create-ui-service-provider';
import { registerFeatureModules } from './global-registrations/register-feature-modules';
import { registerPlugins } from './global-registrations/register-plugins';
import { registerStoreModules } from './global-registrations/register-store-modules';
import { initGtm } from '@/plugins/vue-gtm/index.js';

export const mountApp = ({
  router,
  store,
  vuetify,
  i18n,
  uiNotifier,
  sentry,
  httpErrorParser,
  centrifuge,
  ...rest
}) => {
  const app = createApp(App);

  registerPlugins({
    app,
    plugins:
      [
        vuetify,
        router,
        store,
        i18n,
        Notifications,
      ],
  });

  const errorHandler = new ErrorHandler({
    httpErrorParser,
    errorTracker: sentry({ app, router }),
    uiNotifier,
    defaultErrorMsg: I18N_ERROR_KEYS.DEFAULT,
    logger: {
      error: console.error,
    },
    i18n,
  });

  registerFeatureModules(app, {
    router,
    store,
    vuetify,
    i18n,
    h,
    uiNotifier,
    httpErrorParser,
    errorHandler,
    ...rest,
  });
  registerStoreModules({
    router,
    store,
    vuetify,
    i18n,
    uiNotifier,
    sentry,
    httpErrorParser,
    errorHandler,
    ...rest,
  });

  createUiServiceProvider({
    vuetify,
    i18n,
    uiNotifier,
    store,
    centrifuge,
    app,
  });

  const isProductionEnv = import.meta.env.VITE_ENV === 'production';

  if (isProductionEnv) {
    initGtm(app, { id: 'GTM-TPLMTK' });
  }

  app.mount('#app');
};
