import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_PAYMENT_METHODS_TYPES,
  MUTATION_SET_IS_LOADING_TYPES,
} from '../types';
import { PaymentMethodsTypesMapper } from '../mappers/payment-methods-types-mapper';
import { PaymentMethodType } from '../entities/payment-method-type';

export class FetchPaymentMethodsTypes extends Action {
  _api;
  _errorHandler;

  constructor({ officeApi, errorHandler }) {
    super();

    this._api = officeApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchPaymentMethodsTypes._withLoading(async () => {
      try {
        const paymentMethodsTypesMapper = new PaymentMethodsTypesMapper((opts) => new PaymentMethodType(opts));
        const paymentMethodsTypes = await this._api.getPaymentMethodsTypes(
          (data) => paymentMethodsTypesMapper.mapPaymentMethodsTypes(data),
        );

        commit(MUTATION_SET_PAYMENT_METHODS_TYPES, paymentMethodsTypes);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchPaymentMethodsTypes');
      }
    }, commit, MUTATION_SET_IS_LOADING_TYPES);
  }
}
