import { Action } from '@amarkets/atlas/esm/lib/action';
import { UserSession } from '../entities/user-session';
import { SessionsMapper } from '../mappers/list-of-sessions';
import { MUTATION_SET_IS_LOADING_STATUS } from '../types';

export class GetListOfUserSessions extends Action {
  _api;
  _errorHandler;

  constructor({ authApi, errorHandler }) {
    super();

    this._api = authApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { uuid, onSuccess = () => {} }) {
    await GetListOfUserSessions._withLoading(async () => {
      try {
        const sessionsMapper = new SessionsMapper((opts) => new UserSession(opts));
        const listOfUserSessions = await this._api.getListOfSessions(
          { uuid }, (data) => sessionsMapper.mapSessions(data),
        );

        onSuccess(listOfUserSessions);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute GetListOfUserSessions');
      }
    }, commit, MUTATION_SET_IS_LOADING_STATUS);
  }
}
